import {
  Grid, Typography, Button, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Paper, Dialog, DialogTitle,
  DialogContent, DialogActions, IconButton, Close,
  styled,
  TextField, FormControlLabel, Checkbox, FormControl, InputLabel, FormLabel, RadioGroup, Radio, Select, MenuItem
}
  from "@mui/material";
import { Formik, Form, Field, useFormik, ErrorMessage } from 'formik';
import React, { useState, useEffect } from "react";
import styleCss from "../Style/style"
import { authpostAxios } from "../Utilities/commonAxios";
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import '../Style/Loader.css'
import AddIcon from '@mui/icons-material/Add';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { confirmationAlertwithButtonForUserCreation } from '../Utilities/sweetAlert';


const Add_user = () => {
  const navigate = useNavigate()

  const [userRoles, setuserRoles] = useState([]);

  const submitSocialForm = useFormik({
    enableReinitialize: true,
    initialValues: {
      user_first_name: '',
      user_last_name: '',
      user_email: '',
      user_password: '',
      role_type: ''
    },
    validationSchema: Yup.object({
      user_first_name: Yup.string()
        .required('Please Enter the first Name')
        .matches(/^\S+$/, 'Please enter a valid first name (no spaces allowed)'),
      user_last_name: Yup.string()
        .required('Please Enter the last Name')
        .matches(/^\S+$/, 'Please enter a valid last name (no spaces allowed)'),
      user_email: Yup.string()
        .required('Please Enter the user email')
        .matches(/^\S+$/, 'Please enter a valid email (no spaces allowed)')
        .email('Please enter a valid email address'),
      user_password: Yup.string()
        .required('Please Enter the password')
        .matches(/^\S+$/, 'Please enter a password (no spaces allowed)'),
      role_type: Yup.string().required('Please select role'),
    }),
    onSubmit: async (values, { resetForm, setErrors }) => {
      // Validate the form
      const errors = await submitSocialForm.validateForm();
  
      if (Object.keys(errors).length === 0) { // No validation errors
        const confirmAlert = await confirmationAlertwithButtonForUserCreation("Are you sure you want to create the user?");
        
        if (confirmAlert.isConfirmed) {
          const formData = {
            userFirstName: values.user_first_name,
            userLastName: values.user_last_name,
            userEmail: values.user_email,
            userPassword: values.user_password,
            userRole: values.role_type
          };
          
          try {
            let AddUserResponse = await authpostAxios({
              url: `${process.env.REACT_APP_BACKENDURL}api/add-user`,
              data: formData,
            });
            
            if (AddUserResponse?.data?.flag === 1) {
              toast.success(AddUserResponse?.data?.message);
              resetForm();
            } else {
              toast.error(AddUserResponse?.data?.message);
            }
          } catch (error) {
            toast.error('An error occurred while creating the user.');
          }
        }
      } else {
        setErrors(errors); // Set validation errors in Formik state
      }
    },
  });

  const getRoleTyesAPI = async () => {
    try {
      const userRoleResponse = await authpostAxios({
        url: `${process.env.REACT_APP_BACKENDURL}api/get-user-roles`,
        data: '',
      });
      setuserRoles(userRoleResponse.data.data)

    } catch (error) {
      setuserRoles([])

    }
  }
  useEffect(() => {
    getRoleTyesAPI()
  }, []);

  console.log(userRoles);

  return (
    <form onSubmit={submitSocialForm.handleSubmit}>
      <Grid container style={styleCss.commonpaddingtop}>

        <Grid container xs={12} spacing={2} style={{ marginBottom: '10px' }}>
          <Grid item xs={6}>
            <h2 style={{ textAlign: 'left' }}>Create User</h2>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <Link to="/list_user" style={{ textDecoration: 'none' }}>
              <Button variant="contained">
                User list
              </Button>
            </Link>
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginBottom: '10px' }}>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                label="First Name"
                id="user_first_name"
                name="user_first_name"
                value={submitSocialForm.values.user_first_name}
                onChange={submitSocialForm.handleChange}
              />
            </FormControl>
            <Grid sx={{ color: 'red', fontSize: '12px', fontWeight: 'bold' }}>
              {submitSocialForm.errors.user_first_name}
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginBottom: '10px' }}>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                label="Last Name"
                id="user_last_name"
                name="user_last_name"
                value={submitSocialForm.values.user_last_name}
                onChange={submitSocialForm.handleChange}
              />
            </FormControl>
            <Grid sx={{ color: 'red', fontSize: '12px', fontWeight: 'bold' }}>
              {submitSocialForm.errors.user_last_name}
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginBottom: '10px' }}>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                label="Email Id"
                id="user_email"
                name="user_email"
                value={submitSocialForm.values.user_email}
                onChange={submitSocialForm.handleChange}
              />
            </FormControl>
            <Grid sx={{ color: 'red', fontSize: '12px', fontWeight: 'bold' }}>
              {submitSocialForm.errors.user_email}
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginBottom: '10px' }}>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                label="Password"
                id="user_password"
                name="user_password"
                value={submitSocialForm.values.user_password}
                onChange={submitSocialForm.handleChange}
              />
            </FormControl>
            <Grid sx={{ color: 'red', fontSize: '12px', fontWeight: 'bold' }}>
              {submitSocialForm.errors.user_password}
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginBottom: '10px' }}>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel id="select-label">Select Role</InputLabel>
              <Select
                labelId="select-label"
                id="demo-simple-select"
                label="Roles"
                name="role_type"
                value={submitSocialForm.values.role_type}
                onChange={submitSocialForm.handleChange}
              >
                {userRoles.map((roles) => (
                  <MenuItem key={roles.role_id} value={roles.role_id}>
                    {roles.role_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Grid sx={{ color: 'red', fontSize: '12px', fontWeight: 'bold' }}>
              {submitSocialForm.errors.role_type}
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginTop: '5px', marginLeft: '5px' }}>
          <Button type="submit" variant="contained" color="primary" style={{ marginTop: '16px' }}>
            Save
          </Button>
        </Grid>
      </Grid>
    </form>


  )
}

export default Add_user