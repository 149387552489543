import React, { useEffect, useState } from "react";
import { Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import { authpostAxios } from "../Utilities/commonAxios";
import { useDispatch } from "react-redux";
import { PostDetailsModal } from "../Utilities/postDetailsModal";
import { PostListTable } from "./postListTable";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Button from '@mui/material/Button';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const ReportSociallist = ({ customeSelectStatus, startDate, endDate, restDateTime }) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [getPostLists, setGetPostLists] = useState([]);
  const [childComponentAffect, setChildComponent] = useState(false);
  const handleAffect = () => {
    setChildComponent(true);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const reportLink = (value) => {
    if (value === 0) {
      return `${process.env.REACT_APP_BACKENDURL}api/get-fb-post-list`;
    } else if (value === 1) {
      return `${process.env.REACT_APP_BACKENDURL}api/get-insta-post-list`;
    } else if (value === 2) {
      return `${process.env.REACT_APP_BACKENDURL}api/get-linkedin-post-list`;
    } else if (value === 3) {
      return `${process.env.REACT_APP_BACKENDURL}api/get-youtube-post-list`;
    } else if (value === 4) {
      return `${process.env.REACT_APP_BACKENDURL}api/get-twitter-post-list`;
    }
  };

  const reportDateTime = () => {
    return {
      customeSelectStatus,
      startDate,
      endDate
    }
  }
  const getPostListsFromAPI = async () => {
    await authpostAxios(
      {
        url: reportLink(value),
        data: reportDateTime(),
      },
      dispatch
    ).then((response) => {
      setGetPostLists(response?.data?.data);
    });
  };
  useEffect(() => {
    getPostListsFromAPI();
  }, [value, customeSelectStatus, startDate, endDate]);


  useEffect(() => {
    restDateTime()
  }, [value]);
  // Post Modal
  const [isOpen, setIsOpen] = useState(false);
  const [popupData, setPopupData] = useState("");
  const [selectedSocialMediaType, setSelectedSocialMediaType] = useState("");
  const handlePopupOpen = (data, socialMedia) => {
    setPopupData(data);
    setIsOpen(true);
    setSelectedSocialMediaType(socialMedia);
  };
  const handlePopupClose = () => setIsOpen(false);
  useEffect(() => {
    getPostListsFromAPI()
    setChildComponent(false);
  }, [childComponentAffect]);
  return (
    <>
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          sx={{
            minHeight: "15px",
            height: "38px",
            padding: "0px",
            "& .css-1aquho2-MuiTabs-indicator": {
              background: "unset",
              padding: "0",
            },
            "& .css-1l0dw58-MuiTabs-root": {
              minHeight: "15px",
            },
            "& .MuiTab-textColorPrimary.Mui-selected": {
              backgroundColor: "#e9eaea",
              borderRadius: "3px",
            },
            "& .css-1h9z7r5-MuiButtonBase-root-MuiTab-root": {
              minHeight: "30px",
            },
          }}
        >
          <Tab label="Facebook" className="innertab-style" />
          <Tab label="Instagram" className="innertab-style" />
          <Tab label="LinkedIn" className="innertab-style" />
          <Tab label="Youtube" className="innertab-style" />
          <Tab label="Twitter" className="innertab-style" />
        </Tabs>
      </Box>

      <Box sx={{ width: "100%", marginTop: "20px" }}>
        <CustomTabPanel value={value} index={0}>
          <Grid md={12} sx={{ marginBottom: "15px" }}>
            <PostListTable
              mediaType="fb"
              lists={getPostLists}
              handlePopupOpen={handlePopupOpen}
              onAffect={handleAffect}
            />
          </Grid>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Grid md={12} sx={{ marginBottom: "15px" }}>
            <PostListTable
              mediaType="insta"
              lists={getPostLists}
              handlePopupOpen={handlePopupOpen}
              onAffect={handleAffect}
            />
          </Grid>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <Grid md={12} sx={{ marginBottom: "15px" }}>
            <PostListTable
              mediaType="linkedin"
              lists={getPostLists}
              handlePopupOpen={handlePopupOpen}
              onAffect={handleAffect}
            />
          </Grid>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <Grid md={12} sx={{ marginBottom: "15px" }}>
            <PostListTable
              mediaType="youtube"
              lists={getPostLists}
              handlePopupOpen={handlePopupOpen}
              onAffect={handleAffect}
            />
          </Grid>

        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          <Grid md={12} sx={{ marginBottom: "15px" }}>
            <PostListTable
              mediaType="twitter"
              lists={getPostLists}
              handlePopupOpen={handlePopupOpen}
              onAffect={handleAffect}
            />
          </Grid>

        </CustomTabPanel>
      </Box>
      <PostDetailsModal
        handlePopupClose={handlePopupClose}
        isOpen={isOpen}
        dialogTitle="VIEW POST DETAILS"
        popupData={popupData}
        selectedSocialMediaType={selectedSocialMediaType}
      />
    </>
  );
};

export default ReportSociallist;
