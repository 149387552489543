import { Box,TextField, Grid, Tooltip, Typography } from "@mui/material"
import Campaign2 from "../img/linked-campaign.png"
import styleCss from "../Style/style"
import { PieChart } from '@mui/x-charts/PieChart';
import Slider from '@mui/material/Slider';
import React, { useState } from 'react';
import LocationIcon1 from '../img/camplocation-icon1.png'
import LocationIcon2 from '../img/camplocation-icon2.png'
import LocationIcon3 from '../img/camplocation-icon3.png'
import GoogleHeadIcon from '../img/google-headicon.png'
import SliderRange from '@mui/material/Slider';


const pieParams = { height: 150, margin: { right: 5 } };
const palette = ['#34A853', '#d9d9d9'];


const MAX = 10000;
const MIN = 0;
const marks = [
    {
        value: MIN,
        label: '',
    },
    {
        value: MAX,
        label: '',
    },
];


const GoogleCampaign = ({formikValues, handleSubmit,socialytubecampigndetaillist,formikSetFieldValue}) => {

    
    const MIN = 100;
    const MAX = 100000;

 

    const [budget, setBudget] = useState('');
    const revenue = 1.5; // Fixed Average Revenue per Conversion
    const conversionRate = 0.03; // Fixed Conversion Rate (3%)
    const cpc = 3; // Static CPC
    const cpv = 2; // Static CPV
    const [result, setResult] = useState('');


    const calculateMetrics = (budget) => {
        const budgetValue = parseFloat(budget);
        if (budgetValue) {
          // Calculate metrics
          const totalClicks = budgetValue / cpc;
          const totalViews = budgetValue / cpv;
          const requiredConversions = budgetValue / revenue;
          const actualConversions = totalClicks * conversionRate;
    
          // Format the results
          const total_click = Math.floor(totalClicks);
          const total_view = Math.floor(totalViews);
          const required_conversions = Math.floor(requiredConversions);
          const expect_conversions = Math.floor(actualConversions);
    
          setResult({
            total_click,
            total_view,
            required_conversions,
            expect_conversions,
          });


          formikSetFieldValue('youtube_estimated_click', total_click);
          formikSetFieldValue('youtube_estimated_view', total_view);
          formikSetFieldValue('youtube_estimated_conversion', required_conversions);
          formikSetFieldValue('youtube_expected_conversion', expect_conversions);

    
          // Log the results
          //(total_click, total_view, required_conversions, expect_conversions);
        } else {
          setResult({
            total_click: '',
            total_view: '',
            required_conversions: '',
            expect_conversions: '',
          });
        }
      };

    //(result);

    
    const adDetails = socialytubecampigndetaillist.length > 0 ? socialytubecampigndetaillist[0] : {};
    //(adDetails);

    const campagin_name = adDetails?.ytube_campaign_name ?? '';
    const ad_startdate = adDetails?.ad_startdate ?? '';
    const ad_enddate = adDetails.ad_enddate ?? '';
    const ytube_ads_reach_gender_type = adDetails.ytube_ads_reach_gender_type ?? '';
    const account_name = adDetails?.screen_name ?? '';
    const page_name = adDetails?.screen_name ?? '';
    const campaign_objective = adDetails?.campaign_objective ?? '';
    const ytube_ads_website_url = adDetails?.ytube_ads_website_url ?? '';

    let campaignobjective = ''; // Define outside the blocks

    if (campaign_objective === 'grow_customer_base') {
        campaignobjective = 'Grow Customer Base';
    } else if (campaign_objective === 'get_more_website_visitors') {
        campaignobjective = 'Website Visitors';
    }

    let countryName = '';
    let city = '';
    let state = '';

    if (adDetails?.ytube_ads_reach_location) {
        try {
            // Parse the JSON string into a JavaScript array of objects
            const data = JSON.parse(adDetails.ytube_ads_reach_location);

            // Extract data from the first object if available
            if (Array.isArray(data) && data.length > 0) {
                countryName = data[0].f_country_name || '';
                city = data[0].f_name || '';  // Assuming f_name is the field for city
                state = data[0].f_region || '';
            }
        } catch (error) {
            console.error('Error parsing JSON:', error);
        }
    }

        const handleChildChange = (event, newValue) => {
            formikSetFieldValue('youtube_budget', newValue);
            calculateMetrics(newValue);

            //(handleChildChange);
            formikSetFieldValue('youtube_text_value', newValue);
            calculateMetrics(newValue);
        };

        const setSlideVal = (value) => {
            formikSetFieldValue('youtube_budget', value);
            formikSetFieldValue('youtube_text_value', value);
            calculateMetrics(value);
        };



    const ValueLabelComponent = (props) => {
        const { children, open, value } = props;
        return (
            <Tooltip open={open} enterTouchDelay={0} placement="bottom" title={value} arrow>
                {children}
            </Tooltip>
        );
    };
    return (
        <>
            <Grid sx={styleCss.CampaignBox}>
                <Typography sx={{
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    fontWeight: 'normal',
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: 'normal',
                    letterSpacing: 'normal',
                    textAlign: 'left',
                    color: '#555',
                    marginTop: '10px',
                    marginBottom: '10px',
                }}>Campaign Objective</Typography>
                <Typography sx={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '1px', }}>
                    Traffic
                </Typography>
                <Typography sx={{
                    fontFamily: 'Inter',
                    fontSize: '12px',
                    fontWeight: 'normal',
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: 'normal',
                    letterSpacing: 'normal',
                    textAlign: 'left',
                    color: '#000',
                }}>
                    Drive visitors to your website or marketing landing pages.
                </Typography>
                <Grid sx={{
                    padding: '12px 20px',
                    background: 'linear-gradient(45deg, #4285F4, #DB4437, #F4B400, #0F9D58)',
                    marginTop: '15px'
                }}>
                    <Typography sx={{
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        fontWeight: 500,
                        fontStretch: 'normal',
                        fontStyle: 'normal',
                        lineHeight: 'normal',
                        letterSpacing: 'normal',
                        textAlign: 'left',
                        color: '#fff',
                    }}><img src={GoogleHeadIcon} style={{
                        verticalAlign: 'middle', marginRight: '10px',
                    }} /> { campagin_name }</Typography>
                </Grid>
                <Grid sx={{ textAlign: 'center', marginTop: '15px', marginBottom: '12px' }}>
                    <PieChart
                        colors={palette}
                        series={[{ 
                            data: [
                                { name: 'Estimated Click', value: result.total_click }, 
                                { name: 'Estimated View', value: result.total_view  }
                            ] 
                        }]}
                      
                        {...pieParams}
                    />
                </Grid>
                  { /*      
                <Grid container>
                    <Grid md={6} xs={6}>
                        <Typography sx={{ fontSize: '12px', fontWeight: 'normal', color: '#000', textAlign: 'center', fontFamily: 'Inter' }}>
                            Appro.  Reach
                        </Typography>
                        <Typography sx={{
                            fontSize: '18px',
                            fontWeight: 'bold',
                            fontStretch: 'normal',
                            fontStyle: 'normal',
                            lineHeight: 'normal',
                            letterSpacing: 'normal',
                            textAlign: 'center',
                            color: '#34A853',
                            fontFamily: 'Inter',
                            marginTop: '5px'
                        }}>
                            32.0k to 93.2k
                        </Typography>
                    </Grid>
                    <Grid md={6} xs={6}>
                        <Typography sx={{ fontSize: '12px', fontWeight: 'normal', color: '#000', textAlign: 'center', fontFamily: 'Inter' }}>
                            Appro. Page Like
                        </Typography>
                        <Typography sx={{
                            fontSize: '18px',
                            fontWeight: 'bold',
                            fontStretch: 'normal',
                            fontStyle: 'normal',
                            lineHeight: 'normal',
                            letterSpacing: 'normal',
                            textAlign: 'center',
                            color: '#34A853',
                            fontFamily: 'Inter',
                            marginTop: '5px'
                        }}>
                            357.1k
                        </Typography>
                    </Grid>
                </Grid>
                   */} 

                <Grid md={12}>
                    <Typography sx={{
                        fontSize: '14px',
                        fontWeight: 'normal',
                        fontStretch: 'normal',
                        fontStyle: 'normal',
                        lineHeight: 'normal',
                        letterSpacing: 'normal',
                        textAlign: 'left',
                        color: '#555',
                        marginTop: '20px',
                        fontFamily: 'Inter',
                    }}>Budget</Typography>

                    <Box sx={{ width: '100%', marginTop: '10px' }}>
                        <SliderRange
                        valueLabelDisplay="auto"
                        aria-labelledby="range-slider-facebook"
                        name="youtube_budget"
                        value={formikValues.youtube_text_value}
                        onChange={handleChildChange}
                        //onChange={(event, newValue) => formikValues('whatsapp_budget', newValue)}
                        min={0}
                        max={100000}
                        sx={{
                            '& .MuiSlider-thumb': {
                                color: 'linear-gradient(45deg, #4285F4, #DB4437, #F4B400, #0F9D58)',
                            },
                            '& .MuiSlider-track': { 
                                bgcolor: 'linear-gradient(45deg, #4285F4, #DB4437, #F4B400, #0F9D58)',
                                border: '1px solid transparent',
                            },
                            '& .MuiSlider-rail': {
                                bgcolor: 'linear-gradient(45deg, #4285F4, #DB4437, #F4B400, #0F9D58)',
                            },

                        }}
                        />
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography
                                variant="body2"
                                onClick={() => setSlideVal(MIN)}
                                sx={{ cursor: 'pointer', color: '#555', fontSize: '16px', fontFamily: 'Inter' }}
                            >
                                {formikValues.youtube_budget}
                            </Typography>
                            <Typography
                                variant="body2"
                                onClick={() => setSlideVal(MAX)}
                                sx={{ cursor: 'pointer', color: '#555', fontSize: '16px', fontFamily: 'Inter' }}
                            >
                                {MAX}
                            </Typography>
                        </Box>

                        <TextField
                        type="number"
                        name="youtube_text_value"
                        label="Amount"
                        value={formikValues.youtube_text_value}
                        onChange={(e) => {
                        const value = e.target.value;
                        formikSetFieldValue('youtube_text_value', parseInt(value));
                        formikSetFieldValue('youtube_budget', parseInt(value));
                        calculateMetrics(parseInt(value))
                        }}
                        sx={{ mt: 2, width: '100%' }}
                        />
                   </Box>
               </Grid>

                <Grid md={12} sx={{ borderBottom: '1px solid #d6d6d0', marginTop: '15px', marginBottom: '15px' }}></Grid>
                <Grid container>

                <Grid md={12}>
                    <Typography sx={{
                        fontSize: '14px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        color: '#555',
                        marginTop: '20px',
                        fontFamily: 'Inter',
                        marginTop: '12px'
                    }}>
                        {account_name}
                    </Typography>
                </Grid>

                <Grid md={12}>
                    <Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: '#000', fontFamily: 'Inter', textAlign: 'center', }}>
                        Page Name :  {page_name}
                    </Typography>
                </Grid>
                <Grid md={12}>
                    <Typography sx={{ fontSize: '14px', fontWeight: 'normal', color: '#000', fontFamily: 'Inter', textAlign: 'center', }}>
                        Objective : {campaignobjective}
                    </Typography>
                </Grid>
                <Grid md={12}>
                    <Typography sx={{ fontSize: '14px', fontWeight: 'normal', color: '#000', fontFamily: 'Inter', textAlign: 'center', }}>
                        Website link : {ytube_ads_website_url}
                    </Typography>
                </Grid>

                <Grid md={12} sx={{ borderBottom: '1px solid #d6d6d0', marginTop: '15px', marginBottom: '15px' }}></Grid>
                <Grid container>

                    <Grid md={3} xs={3}>
                        <Grid sx={{
                            padding: '10px',
                            borderRadius: '30px',
                            backgroundColor: '#34A853',
                            width: '40px',
                            height: '40px',
                            display: 'grid',
                            placeContent: 'center',
                            marginBottom: '8px'
                        }}>
                            <img src={LocationIcon3} style={{ width: '19px' }} />
                        </Grid>
                    </Grid>
                    <Grid md={3} xs={3}>
                        <Typography sx={{
                            fontSize: '11px',
                            fontWeight: 'normal',
                            fontStretch: 'normal',
                            fontStyle: 'normal',
                            lineHeight: 'normal',
                            letterSpacing: 'normal',
                            textAlign: 'left',
                            color: '#555',
                            fontFamily: 'Inter',
                        }}>Estimated Click</Typography>

                        <Typography sx={{
                            fontFamily: 'Inter',
                            fontSize: '14px',
                            color: '#000',
                            paddingTop: '4px',
                            marginBottom: '20px'
                        }}>
                           {result.total_click}
                        </Typography>
                    </Grid>

                    <Grid md={3} xs={3} >
                        <Grid sx={{
                            padding: '10px',
                            borderRadius: '30px',
                            backgroundColor: '#34A853',
                            width: '40px',
                            height: '40px',
                            display: 'grid',
                            placeContent: 'center',
                            marginBottom: '8px'
                        }}>
                            <img src={LocationIcon3} style={{width:'19px'}}/>
                        </Grid>
                    </Grid>
                    <Grid md={3} xs={3}>
                    <Typography sx={{
                            fontSize: '11px',
                            fontWeight: 'normal',
                            fontStretch: 'normal',
                            fontStyle: 'normal',
                            lineHeight: 'normal',
                            letterSpacing: 'normal',
                            textAlign: 'left',
                            color: '#555',
                            fontFamily: 'Inter',
                        }}>Estimated  View</Typography>

                    <Typography sx={{
                            fontFamily: 'Inter',
                            fontSize: '14px',
                            color: '#000',
                            paddingTop: '4px'
                        }}>
                           {result.total_view}
                        </Typography>
                    </Grid>


                    <Grid md={3} xs={3}>
                        <Grid sx={{
                            padding: '10px',
                            borderRadius: '30px',
                            backgroundColor: '#34A853',
                            width: '40px',
                            height: '40px',
                            display: 'grid',
                            placeContent: 'center',
                            marginBottom: '8px'
                        }}>
                            <img src={LocationIcon3} style={{ width: '19px' }} />
                        </Grid>
                    </Grid>
                    <Grid md={3} xs={3}>
                        <Typography sx={{
                            fontSize: '11px',
                            fontWeight: 'normal',
                            fontStretch: 'normal',
                            fontStyle: 'normal',
                            lineHeight: 'normal',
                            letterSpacing: 'normal',
                            textAlign: 'left',
                            color: '#555',
                            fontFamily: 'Inter',
                        }}>Estimated Conversions</Typography>

                        <Typography sx={{
                            fontFamily: 'Inter',
                            fontSize: '14px',
                            color: '#000',
                            paddingTop: '4px',
                            marginBottom: '20px'
                        }}>
                           {result.required_conversions}
                        </Typography>
                    </Grid>

                    <Grid md={3} xs={3} >
                        <Grid sx={{
                            padding: '10px',
                            borderRadius: '30px',
                            backgroundColor: '#34A853',
                            width: '40px',
                            height: '40px',
                            display: 'grid',
                            placeContent: 'center',
                            marginBottom: '8px'
                        }}>
                            <img src={LocationIcon3} style={{width:'19px'}}/>
                        </Grid>
                    </Grid>
                    <Grid md={3} xs={3}>
                    <Typography sx={{
                            fontSize: '11px',
                            fontWeight: 'normal',
                            fontStretch: 'normal',
                            fontStyle: 'normal',
                            lineHeight: 'normal',
                            letterSpacing: 'normal',
                            textAlign: 'left',
                            color: '#555',
                            fontFamily: 'Inter',
                        }}>Expected  Conversions</Typography>

                    <Typography sx={{
                            fontFamily: 'Inter',
                            fontSize: '14px',
                            color: '#000',
                            paddingTop: '4px'
                        }}>
                           {result.expect_conversions}
                        </Typography>
                    </Grid>

                    <Grid md={3} xs={3}>
                        <Grid sx={{
                            padding: '10px',
                            borderRadius: '30px',
                            backgroundColor: '#34A853',
                            width: '40px',
                            height: '40px',
                            display: 'grid',
                            placeContent: 'center',
                            marginBottom: '8px'
                        }}>
                            <img src={LocationIcon1} style={{width:'19px'}}/>
                        </Grid>
                     </Grid>

                    <Grid md={3} xs={3}>
                    <Typography sx={{
                            fontSize: '15px',
                            fontWeight: 'normal',
                            fontStretch: 'normal',
                            fontStyle: 'normal',
                            lineHeight: 'normal',
                            letterSpacing: 'normal',
                            textAlign: 'left',
                            color: '#555',
                            fontFamily: 'Inter',
                        }}>Location</Typography>
                        <Typography sx={{
                            fontFamily: 'Inter',
                            fontSize: '14px',
                            color: '#000',
                            paddingTop: '4px'
                        }}>
                        {city !== '' ? city : ''}
                        </Typography>
                        <Typography sx={{
                            fontFamily: 'Inter',
                            fontSize: '14px',
                            fontWeight: 'bold',
                            textAlign: 'left',
                            color: '#000',
                        }}>
                        {state !== '' ? state : ''} 
                        </Typography>
                        <Typography sx={{
                            fontFamily: 'Inter',
                            fontSize: '14px',
                            fontWeight: 'bold',
                            textAlign: 'left',
                            color: '#000',
                        }}>
                        {countryName !== '' ? countryName : ''}
                        </Typography>
                    </Grid>


                    <Grid md={3} xs={3} sx={{ textAlign: 'center', marginTop: '0px' }}>
                        <Grid sx={{
                            padding: '10px',
                            borderRadius: '30px',
                            backgroundColor: '#34A853',
                            width: '40px',
                            height: '40px',
                            display: 'grid',
                            placeContent: 'center',
                            marginBottom: '8px'
                        }}>
                            <img src={LocationIcon2} style={{ width: '12px' }} />
                        </Grid>
                    </Grid>

                    <Grid md={3} xs={3} sx={{ textAlign: 'center', marginTop: '0px' }}>
                        <Typography sx={{
                            fontSize: '15px',
                            fontWeight: 'normal',
                            fontStretch: 'normal',
                            fontStyle: 'normal',
                            lineHeight: 'normal',
                            letterSpacing: 'normal',
                            textAlign: 'left',
                            color: '#555',
                            fontFamily: 'Inter',
                        }}>Gender</Typography>
                        <Typography sx={{
                            fontFamily: 'Inter',
                            fontSize: '14px',
                            fontWeight: 'bold',
                            textAlign: 'left',
                            color: '#000',
                        }}>
                            All
                        </Typography>
                    </Grid>

                    <Grid md={3} xs={3} sx={{ marginTop: '20px' }}>
                        <Grid sx={{
                            padding: '10px',
                            borderRadius: '30px',
                            backgroundColor: '#34A853',
                            width: '40px',
                            height: '40px',
                            display: 'grid',
                            placeContent: 'center',
                            marginBottom: '8px'
                        }}>
                            <img src={LocationIcon1} style={{ width: '19px' }} />
                        </Grid>
                    </Grid>

                    <Grid md={3} xs={3} sx={{ marginTop: '20px' }}>

                        <Typography sx={{
                            fontFamily: 'Inter',
                            fontSize: '12px',
                            fontWeight: 'bold',
                            textAlign: 'left',
                            color: '#000',
                        }}>
                            Budget
                        </Typography>
                        <Typography sx={{
                            fontFamily: 'Inter',
                            fontSize: '12px',
                            fontWeight: 'bold',
                            textAlign: 'left',
                            color: '#000',
                        }}>
                            {formikValues.youtube_text_value}
                        </Typography>
                        </Grid>
                </Grid>
            
                </Grid>
            </Grid>
        </>
    )
}

export default GoogleCampaign