import { div, Button, Grid, TextField, Typography } from "@mui/material"
import React, { useState, useEffect } from "react";
import styleCss from "../Style/style"
import Box from '@mui/material/Box';
import { authpostAxios } from "../Utilities/commonAxios";
import { Formik, Form, Field, useFormik, ErrorMessage } from 'formik';
import SliderRange from '@mui/material/Slider';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import { PieChart } from '@mui/x-charts/PieChart';
import SocialIcon from '../img/facebook_media.png';
import GoogleIcon from '../img/googleicon_media.png'
import WhatsupIcon from '../img/whatsupicon_media.png';
import MessageIcon1 from '../img/messageicon_media1.png'
import MessageIcon2 from '../img/messageicon_media2.png'
import MessageIcon3 from '../img/messageicon_media3.png'
import MessageIcon4 from '../img/messageicon_media4.png'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import moment from "moment";
import { confirmationAlertwithButtonForUserCreation, errorCampaignAlert } from '../Utilities/sweetAlert';
import { toast } from "react-toastify";
import LinkedIcon from '../img/linkedin_media.png'



const Budgetlisting = () => {

  const navigate = useNavigate()
  const handleEdit = async (roleId) => {
    navigate(`/budgectlistview/${btoa(roleId)}`)
  }


  const backgroundColour = ['#f2f2e8', '#a8989830', '#f7e7e7', '#def7f2']

  const [budgettrackerList, setbudgettrackerList] = useState([]);
  const [chartData, setChartData] = useState([]);

  const getbudgettrackerListFromAPI = async () => {
    try {
      const response = await authpostAxios({
        url: `${process.env.REACT_APP_BACKENDURL}api/get-budget-tracker`,
        data: {}
      });
      setbudgettrackerList(response.data?.data || []);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };


  console.log(budgettrackerList);

  useEffect(() => {
    getbudgettrackerListFromAPI()
    console.log(budgettrackerList);
  }, []);





  const handleDelete = async (budgetTrackerId) => {
    const alterResponse = await confirmationAlertwithButtonForUserCreation("Are you sure you want to delete the budget tracker?");
    if (alterResponse.isConfirmed) {
      const response = await authpostAxios({
        url: `${process.env.REACT_APP_BACKENDURL}api/upd-budget-tracker-inactive`,
        data: { budgetTrackerId: budgetTrackerId },
      })
      if (response.data.flag == 1) {
        toast.success(response.data.message);
        getbudgettrackerListFromAPI()
      } else {
        await errorCampaignAlert(response.data.message)
      }
    }

  }


  const PieChartData = (fb, ytube, whatsapp, mail, sms, ivr, voicebot, linkedin) => {
    return [
      { id: 'Facebook', value: fb, color: '#3B5998' },
      { id: 'Youtube', value: ytube, color: '#F4B400' },
      { id: 'WhatsApp', value: whatsapp, color: '#25D366' },
      { id: 'Mail', value: mail, color: '#DB4437' },
      { id: 'SMS', value: sms, color: '#9966FF' },
      { id: 'Ivr', value: ivr, color: '#FF9F40' },
      { id: 'linkedin', value: linkedin, color: '#FF9F40' },
    ].filter(item => item.value > 0); // Filter out any items with a value of 0
  };





  const backgroundColourSet = (index, styleCss) => {

    const colorIndex = index % backgroundColour.length;
    const backgroundColor = backgroundColour[colorIndex];
    return {
      backgroundColor: backgroundColor,
      marginBottom: '15px',
      ...styleCss.newcardboxstyle,
    };
  };






  return (
    <Grid style={styleCss.commonpaddingtop}>

      <Grid container>
        <Grid item xs={6}>
          <h2 style={{ textAlign: 'left' }}>Budget Tracker List</h2>
        </Grid>
        <Grid item xs={6} style={{ textAlign: 'right' }}>
          <Link to="/media-type" style={{ textDecoration: 'none' }}>
            <Button variant="contained">
              Add Budget
            </Button>
          </Link>
        </Grid>


      </Grid>
      {budgettrackerList && budgettrackerList.length != 0 &&
        budgettrackerList?.map((budgetList, index) => (
          <Grid
            container
            item
            columnSpacing={1}
            style={backgroundColourSet(index, styleCss)}
            key={index}
          >
            <Grid item md={2} sx={{ textAlign: 'center' }}>
              <Typography
                sx={{
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  color: '#000',
                  fontWeight: 500,
                  marginBottom: '4px',
                  wordBreak: 'break-word',
                  overflowWrap: 'break-word',
                }}
              >
                {budgetList?.budget_tracker_name}
              </Typography>
            </Grid>
            <Grid item md={2} sx={{ textAlign: 'center' }}>
              <Typography sx={{ fontFamily: 'Inter', fontSize: '16px', color: '#000', fontWeight: 500, marginBottom: '4px', }}>
                Total Budget
              </Typography>
              <Typography sx={{ fontFamily: 'Inter', fontSize: '15px', color: '#656262', fontWeight: 500, marginBottom: '4px', }}>
                Rs {budgetList?.total_budget}
              </Typography>
            </Grid>
            <Grid item md={2} sx={{ textAlign: 'center' }}>
              <Grid sx={{ display: 'flex' }}>
                {budgetList?.facebook_ad_posting_id != 0 &&
                  <Grid sx={{
                    padding: '6px 15px 0px 15px',
                    borderRadius: '6px',
                    border: '1px solid rgb(0, 0, 0)',
                    textAlign: 'center',
                    marginRight: '13px'
                  }}>
                    <img src={SocialIcon} />
                  </Grid>
                }
                {budgetList?.youtube_ad_posting_id != 0 &&
                  <Grid sx={{
                    padding: '6px 15px 0px 15px',
                    borderRadius: '6px',
                    border: '1px solid rgb(0, 0, 0)',
                    textAlign: 'center',
                    marginRight: '13px'
                  }}>
                    <img src={GoogleIcon} />
                  </Grid>
                }
                {budgetList?.linkedin_ad_posting_id != 0 &&
                  <Grid sx={{
                    padding: '6px 15px 0px 15px',
                    borderRadius: '6px',
                    border: '1px solid rgb(0, 0, 0)',
                    textAlign: 'center',
                    marginRight: '13px'
                  }}>
                    <img src={LinkedIcon} />
                  </Grid>
                }

              </Grid>
              <Grid sx={{ display: 'flex', marginTop: '15px' }}>
                {budgetList?.is_whatsapp_checked == 1 &&
                  <Grid sx={{
                    padding: '6px 12px 0px 12px',
                    borderRadius: '6px',
                    border: '1px solid rgb(0, 0, 0)',
                    textAlign: 'center',
                    marginRight: '13px'
                  }}>
                    <img src={WhatsupIcon} />
                  </Grid>
                }
                {budgetList?.is_mail_checked == 1 &&
                  <Grid sx={{
                    padding: '6px 12px 0px 12px',
                    borderRadius: '6px',
                    border: '1px solid rgb(0, 0, 0)',
                    textAlign: 'center',
                    marginRight: '13px'
                  }}>
                    <img src={MessageIcon1} />
                  </Grid>
                }
                {budgetList?.is_sms_checked == 1 &&
                  <Grid sx={{
                    padding: '6px 12px 0px 12px',
                    borderRadius: '6px',
                    border: '1px solid rgb(0, 0, 0)',
                    textAlign: 'center',
                    marginRight: '13px'
                  }}>
                    <img src={MessageIcon2} />
                  </Grid>
                }
              </Grid>
              <Grid sx={{ display: 'flex', marginTop: '15px' }}>
                {budgetList?.is_ivr_checked == 1 &&
                  <Grid sx={{
                    padding: '6px 12px 0px 12px',
                    borderRadius: '6px',
                    border: '1px solid rgb(0, 0, 0)',
                    textAlign: 'center',
                    marginRight: '13px'
                  }}>
                    <img src={MessageIcon3} />
                  </Grid>
                }
                {budgetList?.is_voicebot_checked == 1 &&

                  <Grid sx={{
                    padding: '6px 12px 0px 12px',
                    borderRadius: '6px',
                    border: '1px solid rgb(0, 0, 0)',
                    textAlign: 'center',
                  }}>
                    <img src={MessageIcon4} />
                  </Grid>
                }
              </Grid>
            </Grid>

            <Grid sx={{ textAlign: 'center', display: 'flex', marginLeft: '35px' }}>
              <PieChart
                series={[{
                  data: PieChartData(
                    budgetList?.facebook_ad_posting_id != 0 ? budgetList?.facebook_budget_amount : 0,
                    budgetList?.youtube_ad_posting_id != 0 ? budgetList?.youtube_budget_amount : 0,
                    budgetList?.is_whatsapp_checked == 1 ? budgetList?.whatsapp_budget_amount : 0,
                    budgetList?.is_mail_checked == 1 ? budgetList?.mail_budget_amount : 0,
                    budgetList?.is_sms_checked == 1 ? budgetList?.sms_budget_amount : 0,
                    budgetList?.is_ivr_checked == 1 ? budgetList?.ivr_budget_amount : 0,
                    budgetList?.is_voicebot_checked == 1 ? budgetList?.voicebot_budget_amount : 0,
                    budgetList?.linkedin_ad_posting_id != 0 ? budgetList?.linkedin_budget_amount : 0
                  )
                }]}
                height={130}
                width={200}
              />
            </Grid>
            {/* <Grid item md={2} container justifyContent="center" alignItems="center" sx={{ marginTop: '21px', marginLeft: '21px' }}>
              <PieChart
                series={[{ data }]}
                width={200}
                style={{ maxWidth: '100%', color: '#000', height: 'auto', display: 'block' }}
              />
            </Grid> */}
            {/* <Grid item md={2} container justifyContent="center" alignItems="center" style={{ marginTop: '-21px', marginLeft: '21px' }} >
              <PieChart
                series={[
                  {
                    data,
                  },
                ]}
                width={200}
                style={{ maxWidth: '100%', color: '000', height: 'auto', display: 'block', MarginTop: '-21px' }} // Ensures the chart fits within the grid column
              />
            </Grid> */}
            <Grid item md={2}>
              <Grid sx={{ marginBottom: '10px' }}>
                <Typography sx={{ fontFamily: 'Inter', fontSize: '15px', color: '#000', fontWeight: 500, }}>
                  From Date
                </Typography>
                <Typography sx={{ fontFamily: 'Inter', fontSize: '15px', color: '#656262', fontWeight: 400, marginBottom: '10px' }}>
                  {/* {moment(budgetList?.start_date)} */}
                  {moment(budgetList?.start_date).format('MMMM d, hh:mm A')}
                </Typography>
                <Typography sx={{ fontFamily: 'Inter', fontSize: '15px', color: '#000', fontWeight: 500, }}>
                  To Date
                </Typography>
                <Typography sx={{ fontFamily: 'Inter', fontSize: '15px', color: '#656262', fontWeight: 400, }}>
                  {/* {moment(budgetList?.end_date)} */}
                  {moment(budgetList?.end_date).format('MMMM d, hh:mm A')}
                </Typography>
              </Grid>
            </Grid>
            <Grid item md={1} sx={{ textAlign: 'end' }}>
              <Typography sx={{ fontFamily: 'Inter', fontSize: '16px', color: '#000', fontWeight: 500, marginBottom: '5px' }}>
                Action
              </Typography>
              <Grid sx={{ marginTop: '10px' }}>
                <Grid sx={{ display: 'flex', justifyContent: 'end' }}>
                  <Grid sx={{ marginRight: '15px' }}>
                    <div onClick={() => handleEdit(budgetList.budget_tracker_id)} style={{ cursor: 'pointer' }}>
                      <RemoveRedEyeIcon sx={{ color: '#878282' }} />
                    </div>
                  </Grid>
                  <Grid>
                    <DeleteOutlineIcon onClick={() => handleDelete(budgetList.budget_tracker_id)} sx={{ color: 'red' }} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ))}
    </Grid>
  );
}

export default Budgetlisting