import {
  Accordion,
  AccordionSummary,
  Input,
  Tab,
  Tabs,
  Grid,
  Typography,
  Fade,
  AccordionDetails,
  Box,
  Checkbox,
  FormControlLabel,
  Button,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  FormGroup,
  TextField,
} from "@mui/material";
import styleCss from "../Style/style";
import React, { useState, useEffect, useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "quill-emoji/dist/quill-emoji.css";
import "quill-emoji/dist/quill-emoji.js";
import PropTypes from "prop-types";
import Swal from 'sweetalert2';
import {
  RefreshOutlined,
  CloudUpload,
  CalendarMonth,
  AddPhotoAlternateOutlined,
  OndemandVideoOutlined,
  Quickreply,
} from "@mui/icons-material";
import styled from "@emotion/styled";
import Socialpreview from "./socialPreview";
import Socialselect from "./socialSelect";
import imageuploadicon from "../img/add-img.png";
import CloseIcon from "../img/closeicon.png";
import InputBase from "@mui/material/InputBase";
import { toast } from "react-toastify";
import { useFormik, Form } from "formik";
import * as Yup from "yup";
import { Label } from "reactstrap";
import { authpostAxios } from "../Utilities/commonAxios";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import moment from "moment";
import { useDispatch } from "react-redux";
import {
  confirmationAlertwithButton,
  removeVideoconfirmationAlert,
} from "../Utilities/sweetAlert";
import { manageLoader } from "../Reduxstore/loaderSlice";
import { Link, useNavigate } from "react-router-dom";


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const Socialpost = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const quillRef = useRef(null);
  const minDate = dayjs(new Date());
  /*
  const submitSocialForm = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: {
      checkPageSelection: "",
      facebookSelectAll: false,
      facebookSelectedPages: [],
      instaSelectAll: false,
      instaSelectedAccounts: [],
      linkedInPageSelectAll: false,
      linkedInSelectedPages: [],
      linkedInAccountSelectAll: false,
      linkedInSelectedAccounts: [],
      youtubeTitle: "",
      youtubeAccountSelectAll: false,
      youtubeSelectedAccounts: [],
      twitterAccountSelectAll: false,
      twitterSelectedAccounts: [],
      images: [],
      encodedImages: [],
      customImageMessage: "",
      editorContent: "",
      scheduleType: "",
      scheduleDate: "",
      videoPathDetails: [],
    },
    validationSchema: Yup.object().shape({
      checkPageSelection: Yup.string().when(
        [
          "facebookSelectedPages",
          "instaSelectedAccounts",
          "linkedInSelectedPages",
          "linkedInSelectedAccounts",
          "youtubeSelectedAccounts",
          "twitterSelectedAccounts"
        ],
        (
          [
            facebookSelectedPages,
            instaSelectedAccounts,
            linkedInSelectedPages,
            linkedInSelectedAccounts,
            youtubeSelectedAccounts,
            twitterSelectedAccounts
          ],
          schema
        ) =>
          facebookSelectedPages.length === 0 &&
          instaSelectedAccounts.length === 0 &&
          linkedInSelectedPages.length === 0 &&
          linkedInSelectedAccounts.length === 0 &&
          youtubeSelectedAccounts.length === 0 &&
          twitterSelectedAccounts.length === 0
            ? schema.required("Please Select atleast minimum of one page!")
            : schema
      ),
      customImageMessage: Yup.string().when(
        ["encodedImages", "instaSelectedAccounts", "images"],
        ([encodedImages, instaSelectedAccounts, images], schema) =>
          ((instaSelectedAccounts.length > 0 && encodedImages.length === 0 && images.length === 0) ||
          (instaSelectedAccounts.length > 0 && encodedImages.length > 5 && images.length > 5) ||
            encodedImages.length > 5 || images.length > 5)
            ? schema.required("Minimum of 1 & Maximum of 5 image is allowed!")
            : schema
      ),
      editorContent: Yup.string()
        .required("Please enter body content")
        .test(
          "is-not-empty",
          "Please enter body content",
          (value) => value.replace(/<[^>]*>/g, "").trim().length > 0
        ),
      scheduleType: Yup.string().required("Please select schedule Type"),
      scheduleDate: Yup.string().when(
        ["scheduleType"],
        ([scheduleType], schema) =>
          scheduleType === "2"
            ? schema.required("Please select schedule date")
            : schema
      ),
      youtubeTitle: Yup.string().when(
        "youtubeSelectedAccounts",
        ([youtubeSelectedAccounts], schema) =>
          youtubeSelectedAccounts.length > 0
            ? schema.required("Please enter youtube title")
            : schema
      ),
    }),
    onSubmit: async (values, { resetForm }) => {
      if (!submitSocialForm.dirty) {
        toast.error("No data has changed!");
      } else {
        let formData = {
          facebookSelectAll: values.facebookSelectAll,
          facebookSelectedPages: values.facebookSelectedPages,
          instaSelectAll: values.instaSelectAll,
          instaSelectedAccounts: values.instaSelectedAccounts,
          linkedInPageSelectAll: values.linkedInPageSelectAll,
          linkedInSelectedPages: values.linkedInSelectedPages,
          linkedInAccountSelectAll: values.linkedInAccountSelectAll,
          linkedInSelectedAccounts: values.linkedInSelectedAccounts,
          youtubeTitle:
            values.youtubeSelectedAccounts.length > 0
              ? values.youtubeTitle
              : "",
          youtubeAccountSelectAll: values.youtubeAccountSelectAll,
          youtubeSelectedAccounts: values.youtubeSelectedAccounts,
          twitterAccountSelectAll: values.twitterAccountSelectAll,
          twitterSelectedAccounts: values.twitterSelectedAccounts,
          editorContent: values.editorContent,
          encodedImages: values.encodedImages,
          videoPathDetails: values.videoPathDetails,
          scheduleType: values.scheduleType,
          scheduleDate: values.scheduleType === "2" ? values.scheduleDate : "",
        };
        console.log("formData", formData);
        let { data: socialPostResponse } = await authpostAxios(
          {
            url: `${process.env.REACT_APP_BACKENDURL}api/social-media-post`,
            data: formData,
          },
          dispatch
        );
        if (socialPostResponse.flag === 1)
        {
          console.log('11111111');
          toast.success(socialPostResponse.message);
        }
        else
        {
          toast.error(socialPostResponse.message);
          resetForm();
        }
      }
    },
  });
*/



  const submitSocialForm = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnMount: false,
    initialValues: {
      checkPageSelection: "",
      facebookSelectAll: false,
      facebookSelectedPages: [],
      instaSelectAll: false,
      instaSelectedAccounts: [],
      linkedInPageSelectAll: false,
      linkedInSelectedPages: [],
      linkedInAccountSelectAll: false,
      linkedInSelectedAccounts: [],
      youtubeTitle: "",
      youtubeAccountSelectAll: false,
      youtubeSelectedAccounts: [],
      twitterAccountSelectAll: false,
      twitterSelectedAccounts: [],
      images: [],
      encodedImages: [],
      customImageMessage: "",
      editorContent: "",
      scheduleType: "",
      scheduleDate: "",
      videoPathDetails: [],
      customVideoMessage: "",
      schedulePastDateError: "",
      editorContentTwitter: "",
    },
    validationSchema: Yup.object().shape({
      checkPageSelection: Yup.string().when(
        [
          "facebookSelectedPages",
          "instaSelectedAccounts",
          "linkedInSelectedPages",
          "linkedInSelectedAccounts",
          "youtubeSelectedAccounts",
          "twitterSelectedAccounts"
        ],
        (
          [
            facebookSelectedPages,
            instaSelectedAccounts,
            linkedInSelectedPages,
            linkedInSelectedAccounts,
            youtubeSelectedAccounts,
            twitterSelectedAccounts
          ],
          schema
        ) =>
          facebookSelectedPages.length === 0 &&
            instaSelectedAccounts.length === 0 &&
            linkedInSelectedPages.length === 0 &&
            linkedInSelectedAccounts.length === 0 &&
            youtubeSelectedAccounts.length === 0 &&
            twitterSelectedAccounts.length === 0
            ? schema.required("Please Select atleast minimum of one page!")
            : schema
      ),
      customImageMessage: Yup.string().when(
        ["encodedImages", "instaSelectedAccounts", "images"],
        ([encodedImages, instaSelectedAccounts, images], schema) =>
          ((instaSelectedAccounts.length > 0 && encodedImages.length === 0 && images.length === 0) ||
            (instaSelectedAccounts.length > 0 && encodedImages.length > 5 && images.length > 5) ||
            encodedImages.length > 5 || images.length > 5)
            ? schema.required("Minimum of 1 & Maximum of 5 image is allowed!")
            : schema
      ),
      editorContent: Yup.string()
        .required("Please enter body content")
        .test(
          "is-not-empty",
          "Please enter body content",
          (value) => value.replace(/<[^>]*>/g, "").trim().length > 0
        )
        .when("twitterSelectedAccounts", ([twitterSelectedAccounts], schema) =>
          twitterSelectedAccounts.length > 0
            ? schema.test(
              "maxLengthWithoutEmoji",
              "Twitter allows only 255 characters for text post!",
              function (value) {
                const textWithoutEmojis = (value || "").replace(
                  /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{1F1E6}-\u{1F1FF}]/gu,
                  ""
                );
                return textWithoutEmojis.length <= 255; // Adjust length as needed for Twitter
              }
            )
            : schema
        ),


      scheduleType: Yup.string().required("Please select the post type"),
      scheduleDate: Yup.string().when(
        ["scheduleType"],
        ([scheduleType], schema) =>
          scheduleType === "2"
            ? schema.required("Please select schedule date")
            : schema
      ),
      youtubeTitle: Yup.string().when(
        "youtubeSelectedAccounts",
        ([youtubeSelectedAccounts], schema) =>
          youtubeSelectedAccounts.length > 0
            ? schema.required("Please enter youtube title")
            : schema
      ),
      // customImageMessage: Yup.string().when(
      //   ["videoPathDetails", "youtubeSelectedAccounts"],
      //   ([videoPathDetails, youtubeSelectedAccounts], schema) =>
      //     ((youtubeSelectedAccounts.length > 0 && videoPathDetails.length === 0) ||
      //       (youtubeSelectedAccounts.length > 0 && videoPathDetails.length > 1))
      //       ? schema.required("Upload minum 1 video for youtube post !")
      //       : schema
      // ),
      customVideoMessage: Yup.string().when(
        ["videoPathDetails", "youtubeSelectedAccounts"],
        ([videoPathDetails, youtubeSelectedAccounts], schema) =>
          ((youtubeSelectedAccounts.length > 0 && videoPathDetails.length === 0) ||
            (youtubeSelectedAccounts.length > 0 && videoPathDetails.length > 1))
            ? schema.required("Upload minum 1 video for youtube post !")
            : schema
      )
    }),
    onSubmit: async (values, { resetForm }) => {

      const result = await Swal.fire({
        title: 'Are you sure?',
        text: 'Do you want to post this?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, post it!',
        cancelButtonText: 'No, cancel',
      });

      // Proceed with form submission if user confirms
      if (result.isConfirmed) {
        if (!submitSocialForm.dirty) {
          await Swal.fire({
            icon: 'info',
            title: 'No Changes Detected',
            text: 'No data has changed!',
          });
        } else {
          let formData = {
            facebookSelectAll: values.facebookSelectAll,
            facebookSelectedPages: values.facebookSelectedPages,
            instaSelectAll: values.instaSelectAll,
            instaSelectedAccounts: values.instaSelectedAccounts,
            linkedInPageSelectAll: values.linkedInPageSelectAll,
            linkedInSelectedPages: values.linkedInSelectedPages,
            linkedInAccountSelectAll: values.linkedInAccountSelectAll,
            linkedInSelectedAccounts: values.linkedInSelectedAccounts,
            youtubeTitle:
              values.youtubeSelectedAccounts.length > 0
                ? values.youtubeTitle
                : "",
            youtubeAccountSelectAll: values.youtubeAccountSelectAll,
            youtubeSelectedAccounts: values.youtubeSelectedAccounts,
            twitterAccountSelectAll: values.twitterAccountSelectAll,
            twitterSelectedAccounts: values.twitterSelectedAccounts,
            editorContent: values.editorContent,
            encodedImages: values.encodedImages,
            videoPathDetails: values.videoPathDetails,
            scheduleType: values.scheduleType,
            scheduleDate: values.scheduleType === "2" ? values.scheduleDate : "",
          };
          console.log("formData", formData);
          let { data: socialPostResponse } = await authpostAxios(
            {
              url: `${process.env.REACT_APP_BACKENDURL}api/social-media-post`,
              data: formData,
            },
            dispatch
          );
          if (socialPostResponse.flag === 1) {
            await Swal.fire({
              icon: 'success',
              title: 'Success',
              text: socialPostResponse.message,
            });
            navigate('/publish-post');
          } else {
            await Swal.fire({
              icon: 'error',
              title: 'Error',
              text: socialPostResponse.message,
            });
            window.location.reload();
          }
        }
      } else {
        // User canceled the submission
        await Swal.fire({
          icon: 'info',
          title: 'Cancelled',
          text: 'Submission was cancelled.',
        });
        window.location.reload();
      }
    },
  });


  // date
  const handleDateChange = (date) => {
    const currentDateTime = dayjs()
    const scheduleDateTime = dayjs(date)
    if (scheduleDateTime.isBefore(currentDateTime)) {
      submitSocialForm.setErrors({
        schedulePastDateError: 'The scheduled post only accepts future dates and times',
      });
    } else {
      submitSocialForm.setFieldValue("scheduleDate", dayjs(date).format("YYYY-MM-DD HH:mm:ss"));

    }
  };
  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  // image
  useEffect(() => {
    let newEncodedImages = [];
    if (submitSocialForm.values.images.length > 0) {
      submitSocialForm.values.images.map((image, key) => (image.id = key));
      submitSocialForm.values.images.map((image) => {
        newEncodedImages.push(image.src);
      });
      submitSocialForm.setFieldValue("encodedImages", newEncodedImages);
    } else {
      submitSocialForm.setFieldValue("encodedImages", newEncodedImages);
    }
  }, [submitSocialForm.values.images]);
  const handleImageChange = (e) => {
    const newImages = [];
    const files = e.target.files;
    for (let i = 0; i < files.length; i++) {
      if (files[i].size <= 5242880) {
        const reader = new FileReader();
        reader.onload = (e) => {
          newImages.push({
            id: i,
            src: e.target.result,
          });
          submitSocialForm.setFieldValue("images", [
            ...submitSocialForm.values.images,
            ...newImages,
          ]);
        };
        reader.readAsDataURL(files[i]);
      } else {
        toast.error("Image size must be less than 5MB");
      }
    }
  };
  const handleDeleteImage = (id) => {
    const updatedImages = submitSocialForm.values.images.filter(
      (image) => image.id !== id
    );
    submitSocialForm.setFieldValue("images", updatedImages);
  };
  const [editorValue, setEditorValue] = useState("");
  const handleEditorChange = (content) => {
    setEditorValue(content);
    submitSocialForm.setFieldValue("editorContent", content);
  };
  // video upload
  const [uploadedVideoResponse, setUploadedVideoResponse] = useState([]);
  const handleVideoChange = (e) => {
    const files = e.target.files[0];
    const maxSize = 50 * 1024 * 1024;
    if (files.size > maxSize) {
      toast.error("Kindly upload less then 50mb");
    } else {
      dispatch(manageLoader(true));
      const formData = new FormData();
      formData.append("videoFile", e.target.files[0]);
      fetch(`${process.env.REACT_APP_BACKENDURL}api/video-upload`, {
        method: "post",
        body: formData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("userToken")}`,
        },
      })
        .then((response) => response.json())
        .then((videoDataResponse) => {
          dispatch(manageLoader(false));
          setUploadedVideoResponse(videoDataResponse);
          if (videoDataResponse.flag !== 1) {
            toast.error(videoDataResponse.message);
            submitSocialForm.setFieldValue("videoPathDetails", []);
          } else {
            submitSocialForm.setFieldValue(
              "videoPathDetails",
              videoDataResponse?.data?.path
            );
          }
        });

    }

  };
  const handleRemoveVideo = async () => {
    const getConfirmation = await confirmationAlertwithButton(
      "Yes, remove it!"
    );
    if (getConfirmation.isConfirmed) {
      let { data: removeVideoResponse } = await authpostAxios(
        {
          url: `${process.env.REACT_APP_BACKENDURL}api/remove-video`,
          data: { videoUrl: uploadedVideoResponse?.data?.path?.absolutePath },
        },
        dispatch
      );
      if (removeVideoResponse.flag === 1) {
        setUploadedVideoResponse([]);
        submitSocialForm.setFieldValue("videoPathDetails", []);
      }
      removeVideoconfirmationAlert(
        removeVideoResponse.flag,
        removeVideoResponse.message
      );
    }
  };

  const handleClose = async () => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to close this?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, clear it!',
      cancelButtonText: 'No, cancel',
    });
    if (result.isConfirmed) {
      window.location.reload();
    }

  };

  useEffect(() => {
    if (submitSocialForm.values.scheduleType != '2') {
      submitSocialForm.values.scheduleDate = ''
    }
  }, [submitSocialForm.values.scheduleType]);


  return (
    <>
      <Grid style={styleCss.commonpaddingtop} sx={{ mb: 3 }}>
        <form onSubmit={submitSocialForm.handleSubmit}>
          <Grid
            container
            sx={{ border: "solid 1px #edeced", borderRadius: "7px" }}
          >
            <Grid item md={2} sx={{ borderRight: "1px solid #ddd" }}>
              <Socialselect formik={submitSocialForm} />
            </Grid>
            <Grid item md={6} sx={{ padding: "10px 20px" }}>
              <Grid container>
                <Grid md={10}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: "bold",
                      textAlign: "left",
                      color: "#27292f",
                    }}
                  >
                    Compose post
                  </Typography>
                </Grid>

                {submitSocialForm.values.youtubeSelectedAccounts.length > 0 && (
                  <>
                    <Grid md={12} sx={{ marginTop: "10px" }}>
                      <FormControl fullWidth>
                        <TextField
                          value={submitSocialForm.values.youtubeTitle}
                          id="youtubeTitle"
                          name="youtubeTitle"
                          label="Enter youtube title"
                          onChange={submitSocialForm.handleChange}
                          sx={{ width: "100%" }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid md={12} sx={{ marginTop: "10px" }}>
                      <Typography
                        className="error"
                        sx={{ color: "red", fontSize: "12px" }}
                      >
                        {submitSocialForm.touched.youtubeTitle && submitSocialForm.errors.youtubeTitle
                          ? submitSocialForm.errors.youtubeTitle
                          : ""}
                      </Typography>
                    </Grid>
                  </>
                )}

                <Grid md={12} sx={{ mt: 3 }}>
                  <ReactQuill
                    id="editorContent"
                    ref={quillRef}
                    name="editorContent"
                    value={editorValue}
                    onChange={handleEditorChange}
                    style={{ height: "100px" }}
                    theme="snow"
                    modules={{
                      toolbar: {
                        container: [
                          ["emoji"]
                          // ["bold", "italic", "underline"],
                          // ["emoji"], // Add an emoji button
                        ],
                      },
                      "emoji-toolbar": true,
                      // 'emoji-textarea': true, // Enable emoji input in the editor
                    }}
                  />
                </Grid>
              </Grid>
              <Grid md={12} sx={{ marginTop: "50px" }}>
                <Typography
                  className="error"
                  sx={{ color: "red", fontSize: "12px" }}
                >
                  {submitSocialForm.touched.editorContent && submitSocialForm.errors.editorContent
                    ? submitSocialForm.errors.editorContent
                    : ""}
                  {/* {submitSocialForm.touched.editorContentTwitter && submitSocialForm.errors.editorContentTwitter
                    ? submitSocialForm.errors.editorContentTwitter
                    : ""} */}
                </Typography>
              </Grid>
              <Grid container>
                <Grid md={12} sx={{ mt: 3 }}>
                  <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    sx={{
                      "& .Mui-selected, .css-1aquho2-MuiTabs-indicator": {
                        backgroundColor: "#eeeff0",
                        height: "unset",
                        fontSize: "13px",
                        flexDirection: "row",
                        borderRadius: "4px",
                      },
                      "& .css-qmhldy-MuiButtonBase-root-MuiTab-root": {
                        minHeight: "40px",
                      },
                      "& .css-qmhldy-MuiButtonBase-root-MuiTab-root>.MuiTab-iconWrapper":
                      {
                        marginBottom: "0",
                      },
                    }}
                  >
                    <Tab
                      icon={
                        <AddPhotoAlternateOutlined
                          sx={{
                            mr: "3px",
                            fontSize: "20px",
                            verticalAlign: "middle",
                            marginBottom: "0px",
                          }}
                        />
                      }
                      label="Image"
                      {...a11yProps(0)}
                      sx={{
                        padding: "0px 13px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "row",
                      }}
                    />
                    <Tab
                      icon={
                        <OndemandVideoOutlined
                          sx={{
                            mr: "3px",
                            fontSize: "20px",
                            verticalAlign: "middle",
                          }}
                        />
                      }
                      label="Video"
                      {...a11yProps(1)}
                      sx={{
                        padding: "0px 13px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "row",
                      }}
                    />
                  </Tabs>
                  <Grid>
                    <CustomTabPanel value={tabValue} index={0}>
                      <Grid style={styleCss.cardboxstyle}>
                        <Grid container>
                          <Grid md={12}>
                            <InputLabel
                              htmlFor="fileInput"
                              style={{
                                borderRadius: "4px",
                                padding: "10px",
                                cursor: "pointer",
                                marginBottom: "10px",
                              }}
                            >
                              <img
                                src={imageuploadicon}
                                alt="Your Image Alt Text"
                                style={{ height: "60px", width: "60px" }}
                              />
                            </InputLabel>
                            <Input
                              id="fileInput"
                              style={{ display: "none" }}
                              type="file"
                              inputProps={{ multiple: true, accept: "image/*" }}
                              onChange={handleImageChange}
                            />
                          </Grid>
                          {submitSocialForm.values.images &&
                            submitSocialForm.values.images?.map(
                              (image, index) => (
                                <>
                                  <Grid
                                    item
                                    key={index}
                                    md={2}
                                    className="mb-1 mt-3"
                                  >
                                    <div style={{ position: "relative" }}>
                                      <img
                                        src={image.src}
                                        alt={`Thumbnail_${index}`}
                                        style={{
                                          width: "90px",
                                          height: "90px",
                                          float: "left",
                                          margin: "0px",
                                          padding: "5px",
                                          cursor: "pointer",
                                          position: "relative",
                                          overflow: "hidden",
                                        }}
                                      />
                                      <Button
                                        onClick={() => handleDeleteImage(index)}
                                        style={{
                                          position: "absolute",
                                          top: "-18px",
                                          right: "-31px",
                                          padding: "0px",
                                          cursor: "pointer",
                                          background: "unset",
                                          color: "white",
                                          border: "unset",
                                        }}
                                      >
                                        <img src={CloseIcon} alt="Close Icon" />
                                      </Button>
                                    </div>
                                  </Grid>
                                </>
                              )
                            )}
                        </Grid>
                      </Grid>
                      <Typography
                        className="error"
                        sx={{ color: "red", fontSize: "12px" }}
                      >
                        {submitSocialForm.touched.customImageMessage && submitSocialForm.errors.customImageMessage
                          ? submitSocialForm.errors.customImageMessage
                          : ""}
                      </Typography>
                    </CustomTabPanel>

                    <CustomTabPanel value={tabValue} index={1}>
                      <Grid style={styleCss.cardboxstyle}>
                        <Grid md={12}>
                          <InputLabel
                            htmlFor="videoInput"
                            style={{
                              borderRadius: "4px",
                              padding: "10px",
                              cursor: "pointer",
                              marginBottom: "10px",
                            }}
                          >
                            <img
                              src={imageuploadicon}
                              alt="Your Video Alt Text"
                              style={{ height: "60px", width: "60px" }}
                              onChange={handleVideoChange}
                            />
                          </InputLabel>
                          <Input
                            id="videoInput"
                            style={{ display: "none" }}
                            type="file"
                            inputProps={{ accept: "video/*" }}
                            onChange={handleVideoChange}
                          />
                        </Grid>
                        {uploadedVideoResponse &&
                          Object.keys(uploadedVideoResponse).length > 0 &&
                          uploadedVideoResponse.flag === 1 && (
                            <Grid item md={3} className="">
                              <Grid style={{ position: "relative" }}>
                                <video
                                  src={uploadedVideoResponse.data.path.relativePath.replace(
                                    /\\/g,
                                    "\\\\"
                                  )}
                                  autoPlay
                                  muted
                                  controls
                                  style={{
                                    width: "100%",
                                    height: "150px",
                                    padding: "5px",
                                    cursor: "pointer",
                                    position: "relative",
                                    overflow: "hidden",
                                  }}
                                />
                                <Button
                                  style={{
                                    position: "absolute",
                                    top: "3px",
                                    right: "-38px",
                                    padding: "0px",
                                    cursor: "pointer",
                                    background: "unset",
                                    color: "white",
                                    border: "unset",
                                  }}
                                  onClick={handleRemoveVideo}
                                >
                                  <img src={CloseIcon} alt="Close Icon" />
                                </Button>
                              </Grid>
                            </Grid>
                          )}
                      </Grid>
                      <Typography
                        className="error"
                        sx={{ color: "red", fontSize: "12px" }}
                      >
                        {submitSocialForm.touched.customVideoMessage && submitSocialForm.errors.customVideoMessage
                          ? submitSocialForm.errors.customVideoMessage
                          : ""}
                      </Typography>
                    </CustomTabPanel>
                  </Grid>
                </Grid>
              </Grid>
              <Grid style={styleCss.cardboxstyle} sx={{ mt: 3 }}>
                <Grid
                  container
                  spacing={1}
                  sx={{ alignItems: "center", padding: "5px" }}
                >
                  <Grid item md={3}>
                    <Typography
                      sx={{
                        fontSize: 14,
                        fontWeight: "normal",
                        textAlign: "left",
                        color: "#27292f",
                        verticalAlign: "middle",
                      }}
                    >
                      <CalendarMonth sx={{ verticalAlign: "middle" }} /> When to
                      publish{" "}
                    </Typography>
                  </Grid>
                  <Grid md={4} item sx={{ paddingRight: "10px" }}>
                    <FormControl sx={{ m: 1 }} size="small" fullWidth>
                      <InputLabel id="scheduleTypeLabel">Select</InputLabel>
                      <Select
                        name="scheduleType"
                        labelId="scheduleTypeLabel"
                        id="scheduleType"
                        value={submitSocialForm.values.scheduleType}
                        label="Select"
                        onChange={submitSocialForm.handleChange}
                      >
                        <MenuItem value="">Select</MenuItem>
                        <MenuItem value="1">Instant Post</MenuItem>
                        <MenuItem value="2">Schedule Post</MenuItem>
                      </Select>
                    </FormControl>
                    <Typography
                      className="error"
                      sx={{ color: "red", fontSize: "12px" }}
                    >
                      {submitSocialForm.touched.scheduleType && submitSocialForm.errors.scheduleType
                        ? submitSocialForm.errors.scheduleType
                        : ""}
                    </Typography>
                  </Grid>
                  {submitSocialForm.values.scheduleType === "2" && (
                    <Grid md={5} item sx={{ paddingRight: "10px" }}>
                      <FormControl sx={{ m: 1 }} size="small" fullWidth>
                        <InputLabel id="scheduleDateLabel"></InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateTimePicker
                            labelId="scheduleDateLabel"
                            label=""
                            minDateTime={minDate}
                            // minDate={minDate}
                            format="DD/MM/YYYY h:mm A"
                            onChange={handleDateChange}
                            sx={{
                              "& .MuiInputBase-input::placeholder": {
                                opacity: 0,
                              },
                              "& .MuiInputBase-input": { padding: "8px" },
                            }}
                            renderInput={(params) => <TextField {...params} />}
                          // value={scheduleDate}
                          />
                        </LocalizationProvider>
                      </FormControl>
                      <Typography
                        className="error"
                        sx={{ color: "red", fontSize: "12px" }}
                      >
                        {submitSocialForm.touched.scheduleDate && submitSocialForm.errors.scheduleDate
                          ? submitSocialForm.errors.scheduleDate
                          : ""}
                        {submitSocialForm.errors.schedulePastDateError
                          ? submitSocialForm.errors.schedulePastDateError
                          : ""}
                        {/* scheduleDatenew */}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
                <Grid sx={{ borderBottom: "1px solid #ddd" }}> </Grid>
                <Grid sx={{ padding: "10px" }}>
                  <Typography
                    sx={{
                      fontSize: "13px",
                      fontWeight: "normal",
                      textAlign: "left",
                      color: "#416bd7",
                    }}
                  >
                    {submitSocialForm.values.scheduleType === "" ? '' :
                      submitSocialForm.values.scheduleType === "2" ? `Post will be schedule at ${submitSocialForm.values.scheduleDate ? (submitSocialForm.values.scheduleDate) : ''}`
                        : "Post will be published immediately!"}
                    {/* Post will be published immediately! */}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container sx={{ mt: 2, textAlign: "right", mb: "10px" }}>
                <Grid md={12}>
                  <Button
                    onClick={() => handleClose()}
                    variant="contained"
                    sx={{
                      background: "#ddd",
                      fontFamily: "14px",
                      fontWeight: "500",
                      textTransform: "capitalize",
                      fontFamily: "Inter",
                      marginRight: "10px",
                      color: "#717277",
                      padding: "6px 35px",
                      "&:hover": { background: "#ddd", color: "#717277" },
                    }}
                  >
                    Clear
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"

                    sx={{
                      background: "#38cb89",
                      fontFamily: "14px",
                      fontWeight: "500",
                      textTransform: "capitalize",
                      fontFamily: "Inter",
                      padding: "6px 15px",
                      "&:hover": { background: "#38cb89", color: "#fff" },
                    }}
                  >
                    {submitSocialForm.values.scheduleType === "2"
                      ? "Schedule Now"
                      : "Post Now"}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              md={4}
              sx={{ background: "#f7f7f7", padding: "5px 10px" }}
            >
              {/* <Socialpreview /> */}
            </Grid>
          </Grid>
        </form>
      </Grid>
    </>
  );
};

export default Socialpost;
