import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import { useState } from 'react';
import { authpostAxios } from '../Utilities/commonAxios';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import { useDispatch } from "react-redux"
import { toast } from 'react-toastify';
import { confirmationAlertwithButtonForUserCreation } from '../Utilities/sweetAlert';
import Swal from 'sweetalert2';

export default function FadeMenu({ parentId, socialMediaId, commentTitle }) {
    const dispatch = useDispatch()
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const menuItems = [
        { id: 1, value: 'Convert to ticket' },
        { id: 2, value: 'Convert to lead' }
    ];

    const handleSelect = async (id, value) => {
        const response = await confirmationAlertwithButtonForUserCreation(value);
        if (response.isConfirmed) {
            const formData = {
                ticketId: id,
                ticketStatus: value,
                parentcId: parentId,
                socialMediaId: socialMediaId,
                commentTitle: commentTitle
            }
            const { data: updResponse } = await authpostAxios(
                {
                    'url': `${process.env.REACT_APP_BACKENDURL}api/upd-fb-comment-ticket`,
                    'data': formData
                }
                , dispatch);
            console.log("updResponse", updResponse);
            if (updResponse.flag == 1) {
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: `comment ${value}`,
                    showConfirmButton: false,
                    timer: 1500
                });
            } else {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: updResponse?.statusdesc || "error",
                    showConfirmButton: false,
                    timer: 1500
                });
            }

        }

        setAnchorEl(null);
    };

    return (
        <div>
            <Button
                id="fade-button"
                aria-controls={open ? 'fade-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={{ padding: '0px', minWidth: 'unset', }}
            >
                <MoreHorizOutlinedIcon sx={{ color: '#7b7b7b' }} />
            </Button>
            <Menu
                id="fade-menu"
                MenuListProps={{
                    'aria-labelledby': 'fade-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
            >
                {menuItems.map((item) => (
                    <MenuItem
                        key={item.id}
                        onClick={() => handleSelect(item.id, item.value)}
                        sx={{ color: 'blue', fontSize: '13px', padding: '2px 10px' }}
                    >
                        {item.value}
                    </MenuItem>
                ))}
                {/* <MenuItem onClick={handleClose} sx={{color:'blue', fontSize:'13px', padding:'2px 10px'}}>
                Convert to ticket */}
                {/* <DeleteOutlineIcon sx={{fontSize:'20px', marginRight:'5px'}}/> Convert to ticket */}
                {/* </MenuItem>
                <MenuItem onClick={handleClose} sx={{color:'blue', fontSize:'13px', padding:'2px 10px'}}>
                Convert to lead */}
                {/* <NotInterestedIcon sx={{fontSize:'17px',marginRight:'6px'}}/> Convert to lead */}
                {/* </MenuItem> */}
            </Menu>
        </div>
    );
}