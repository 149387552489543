import React, { useState, useEffect } from "react";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
} from "@mui/lab";
import { Grid, Typography,Modal } from "@mui/material";
import styleCss from "../Style/style";
import {
  DeleteOutlined,
  RemoveRedEyeOutlined,
  ShortcutOutlined,
} from "@mui/icons-material";
import { Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { authpostAxios } from "../Utilities/commonAxios";
import moment from "moment";
import { socialMediaLists } from "../Utilities/socialMediaLists";
import { dateDiff } from "../Utilities/customDate";
import { ReplyCommentModal } from "../Utilities/replyCommentModal";
import noImage from '../img/no-image.jpg';


const SocialTimeline = () => {
  const dispatch = useDispatch();
  const { enParentcid, enSmid,enCommentTitle } = useParams();

  const [parent_c_id, social_media_id,comment_title] = [atob(enParentcid), atob(enSmid),atob(enCommentTitle)];
  const [parentCommentLists, setParentCommentLists] = useState([]);
  const [childCommentLists, setChildCommentLists] = useState([]);

  useEffect(() => {
    const getThreadCommentFromAPI = async () => {
      try {
        const response = await authpostAxios(
          {
            url: `${process.env.REACT_APP_BACKENDURL}api/get-child-tree`,
            data: {
              comment_title,
              parent_c_id,
              social_media_id,
            },
          },
          dispatch
        );
        setParentCommentLists(response?.data?.data?.parentComment);
        setChildCommentLists(response?.data?.data?.childComments);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    getThreadCommentFromAPI();
  }, []);
  // Reply Modal
  const [isOpen, setIsOpen] = useState(false);
  const [popupData, setPopupData] = useState("");
  const [commentType, setCommentType] = useState("");
  const handlePopupOpen = (data,type) => {
    setPopupData(data);
    setIsOpen(true);
    setCommentType(type)
  };
  const handlePopupClose = () => setIsOpen(false);
  return (
    <><Grid style={styleCss.commonpaddingtop}>
      <Grid container>
        <Timeline>
          {parentCommentLists &&
            parentCommentLists?.map((parentCommentList, index) => (
              <TimelineItem
                sx={{
                  "&::before": {
                    flex: 0,
                  },
                }}
                key={index}
              >
                <TimelineSeparator>
                  <TimelineDot sx={{ backgroundColor: "#1877f2" }} />
                  <TimelineConnector />
                </TimelineSeparator>

                <TimelineContent
                  sx={{
                    "& .css-13qkvwv-MuiGrid-root": {
                      width: "30%",
                      float: "left",
                    },
                  }}
                >
                  <Grid sx={{ position: "relative" }}>
                    <Grid
                      sx={{
                        border: "1px solid #dddddd6b",
                        borderRadius: "3px",
                        padding: "5px",
                        marginBottom: "10px",
                        display: "flex",
                        alignContent: "stretch",
                        height: "150px",
                        background: "#0970f712",
                      }}
                    >
                      <Grid container item sx={{ height: "0px" }}>
                        <Grid item md={2}>
                          <Grid sx={{ position: "relative" }}>
                            <img
                              src={(parentCommentList.parent_profile_url) ? (parentCommentList.parent_profile_url) : (noImage)}
                              style={{
                                width: "30px",
                                height: "30px",
                                borderRadius: "50px",
                                marginRight: "10px",
                                textAlign: "left",
                              }} />
                            <img
                              src={socialMediaLists[social_media_id]["icon"]}
                              style={{
                                width: "14px",
                                borderRadius: "50px",
                                position: "absolute",
                                bottom: 0,
                                left: "20px",
                              }} />
                          </Grid>
                        </Grid>
                        <Grid item md={8} sx={{ alignItems: "center" }}>
                          <Typography
                            sx={{
                              fontSize: "11px",
                              fontWeight: 500,
                              textAlign: "left",
                              color: "#27292f !important",
                              fontFamily: "Inter",
                            }}
                          >
                            {parentCommentList.parent_comment_name}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "11px",
                              fontWeight: 500,
                              textAlign: "left",
                              color: "#27292f !important",
                              fontFamily: "Inter",
                            }}
                          >
                            {moment(parentCommentList.date_comment_post).format('MMMM D, h:mm A')}
                            {/* {dateDiff(parentCommentList.date_comment_post)} */}
                          </Typography>
                        </Grid>
                        {/* <Grid item md={2} sx={{ textAlign: "end" }}>
                  <Link to="/" style={{ color: "#767e87" }}>
                    <DeleteOutlined sx={{ width: "20px" }} />{" "}
                  </Link>
                </Grid> */}
                        <Grid md={12} style={{ marginTop: "5px" }}>
                          <Typography
                            sx={{
                              color: "#4b4c4f",
                              fontSize: "11px",
                              fontWeight: 600,
                              textAlign: "left",
                              marginRight: "3px",
                              fontFamily: "Inter",
                            }}
                          >
                            {parentCommentList.page_name}
                          </Typography>
                          <Typography
                            sx={{
                              color: "#767e87",
                              fontFamily: "Inter",
                              fontSize: "12px",
                              fontWeight: "normal",
                              textAlign: "left",
                            }}
                          >
                            <span style={{ color: "#4b4c4f" }}>
                              Commented on:{" "}
                            </span>{" "}
                            {/* {readCommentList.comments.length >60  ? `${(readCommentList.comments).substring(0, 60)}...etc` : readCommentList.comments } */}
                            {parentCommentList.parent_comments}
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          sx={{
                            marginTop: "3px",
                            textAlign: "end",
                            position: "absolute",
                            bottom: 10,
                            right: 5,
                          }}
                        >
                          <Grid md={8} sx={{ textAlign: "start" }}>
                            <Typography
                              sx={{
                                color: "#4b4c4f",
                                fontSize: "11px",
                                fontWeight: 600,
                                textAlign: "left",
                                fontFamily: "Inter",
                                marginLeft:'12px',
                              }}
                            >
                              {moment(parentCommentList.date_comment_post).format('MMMM D, h:mm A')}
                            </Typography>
                          </Grid>
                <Grid md={4} sx={{ display: 'flex' , justifyContent: "end" }}>
                  <Link to="#" style={{ color: "#767e87" }} onClick={() => handlePopupOpen(parentCommentList,'parent')}>
                    <ShortcutOutlined sx={{ width: "20px" }} />
                  </Link>
                </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    </Grid>
                    </TimelineContent>
                </TimelineItem>
              ))}
            {childCommentLists &&
              childCommentLists?.map((childCommentList, index) => (
                <TimelineItem
                  sx={{
                    "&::before": {
                      flex: 0,
                    },
                  }}
                  key={index}
                >
                  <Grid sx={{ position: "relative" }}>
                    <Grid
                      sx={{
                        border: "1px solid #dddddd6b",
                        borderRadius: "3px",
                        padding: "5px",
                        marginBottom: "10px",
                        display: "flex",
                        alignContent: "stretch",
                        height: "150px",
                        background: "#0970f712",
                      }}
                    >
                      <Grid container item sx={{ height: "0px" }}>
                        <Grid item md={2}>
                          <Grid sx={{ position: "relative" }}>
                            <img
                              src={(childCommentList.child_profile_url) ? (childCommentList.child_profile_url) : (noImage)}
                              style={{
                                width: "30px",
                                height: "30px",
                                borderRadius: "50px",
                                marginRight: "10px",
                                textAlign: "left",
                              }} />
                            <img
                              src={socialMediaLists[social_media_id]["icon"]}
                              style={{
                                width: "14px",
                                borderRadius: "50px",
                                position: "absolute",
                                bottom: 0,
                                left: "20px",
                              }} />
                          </Grid>
                        </Grid>
                        <Grid item md={8} sx={{ alignItems: "center" }}>
                          <Typography
                            sx={{
                              fontSize: "11px",
                              fontWeight: 500,
                              textAlign: "left",
                              color: "#27292f !important",
                              fontFamily: "Inter",
                            }}
                          >
                            {childCommentList.child_profile_name}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "11px",
                              fontWeight: 500,
                              textAlign: "left",
                              color: "#27292f !important",
                              fontFamily: "Inter",
                            }}
                          > 
                          {moment(childCommentList.date_comment_post).format('MMMM D, h:mm A')}
                            {/* {dateDiff(childCommentList.date_comment_post)} */}
                          </Typography>
                        </Grid>
                        {/* <Grid item md={2} sx={{ textAlign: "end" }}>
                  <Link to="/" style={{ color: "#767e87" }}>
                    <DeleteOutlined sx={{ width: "20px" }} />{" "}
                  </Link>
                </Grid> */}
                        <Grid md={12} style={{ marginTop: "5px" }}>
                          <Typography
                            sx={{
                              color: "#4b4c4f",
                              fontSize: "11px",
                              fontWeight: 600,
                              textAlign: "left",
                              marginRight: "3px",
                              fontFamily: "Inter",
                            }}
                          >
                            
                            {childCommentList.page_name}
                          </Typography>
                          <Typography
                            sx={{
                              color: "#767e87",
                              fontFamily: "Inter",
                              fontSize: "12px",
                              fontWeight: "normal",
                              textAlign: "left",
                            }}
                          >
                            <span style={{ color: "#4b4c4f" }}>
                              Commented on:{" "}
                            </span>{" "}
                            {childCommentList.child_comments}
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          sx={{
                            marginTop: "3px",
                            textAlign: "end",
                            position: "absolute",
                            bottom: 10,
                            right: 5,
                          }}
                        >
                          <Grid md={8} sx={{ textAlign: "start" }}>
                            <Typography
                              sx={{
                                color: "#4b4c4f",
                                fontSize: "11px",
                                fontWeight: 600,
                                textAlign: "left",
                                fontFamily: "Inter",
                                marginLeft:'12px',
                              }}
                            >
                              {moment(childCommentList.date_comment_post).format('MMMM D, h:mm A')}
                            </Typography>
                          </Grid>
                          {/* <Grid md={2} sx={{ textAlign: "end" }}>
                  <Link to="/" style={{ color: "#767e87" }}>
                    <RemoveRedEyeOutlined sx={{ width: "20px" }} />
                  </Link>
                </Grid>*/}
                          <Grid md={2} sx={{ textAlign: "end", justifyContent: "end" }}>
                            <Link to='#' style={{ color: "#767e87" }} onClick={() => handlePopupOpen(childCommentList,'Child')}>
                              <ShortcutOutlined sx={{ width: "20px" }} />
                            </Link>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    </Grid>
                </TimelineItem>
              ))}
          </Timeline>
        </Grid>
      </Grid>
      <ReplyCommentModal
        handlePopupClose={handlePopupClose}
        isOpen={isOpen}
        dialogTitle="Reply to Comment"
        popupData={popupData}
        commentType={commentType}
        setIsOpen={setIsOpen}
      />
    </>
  );
};

export default SocialTimeline;
