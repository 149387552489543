import { Box, Grid, Tooltip, Typography, TextField } from "@mui/material"
import Campaign2 from "../img/linked-campaign.png"
import styleCss from "../Style/style"
import { PieChart } from '@mui/x-charts/PieChart';
import Slider from '@mui/material/Slider';
import React, { useState } from "react";
import LocationIcon1 from '../img/camplocation-icon1.png'
import LocationIcon2 from '../img/camplocation-icon2.png'
import LocationIcon3 from '../img/camplocation-icon3.png'
import SliderRange from '@mui/material/Slider';

const pieParams = { height: 150, margin: { right: 5 } };
const palette = ['#0077B5', '#d9d9d9'];


const MAX = 10000;
const MIN = 0;
const marks = [
    {
        value: MIN,
        label: '',
    },
    {
        value: MAX,
        label: '',
    },
];


const LinkedInCampaign = ({ formikValues, handleSubmit, sociallinkedincampigndetaillist, formikSetFieldValue }) => {
    const MIN = 100;
    const MAX = 100000;
    // const [slideVal, setSlideVal] = React.useState(MIN);
    // const handleSlideChange = (_, newValue) => {
    //     setSlideVal(newValue);
    // };



    const [result, setResult] = useState('');

    const ValueLabelComponent = (props) => {
        const { children, open, value } = props;
        return (
            <Tooltip open={open} enterTouchDelay={0} placement="bottom" title={value} arrow>
                {children}
            </Tooltip>
        );
    };


    const calculateMetrics = (budget) => {
        const budgetValue = parseFloat(budget);
        if (budgetValue) {
            const avgCostPerLead = 300; 
            const avgCostPerConversion = 500; 
            const avgCPM = 2000; // Static CPC
            const avgCPC = 600; // Static CPV

            let totalImpressions = (budget / avgCPM) * 1000;
            let totalClicks = budget / avgCPC;
            let totalLeads = budget / avgCostPerLead;
            let totalConversions = budget / avgCostPerConversion;
            

            setResult({
                totalClicks,
                totalLeads,
                totalImpressions,
                totalConversions,
            });


            formikSetFieldValue('linkedin_estimated_click', Math.floor(totalClicks));
            formikSetFieldValue('linkedin_estimated_leads', Math.floor(totalLeads));
            formikSetFieldValue('linkedin_estimated_impression', Math.floor(totalImpressions));
            formikSetFieldValue('linkedin_expected_conversion', Math.floor(totalConversions));


            // Log the results
            //(total_click, total_view, required_conversions, expect_conversions);
        } else {
            setResult({
                total_click: '',
                total_view: '',
                required_conversions: '',
                expect_conversions: '',
            });
        }
    };

    const setSlideVal = (value) => {
        formikSetFieldValue('linkedin_budget', value);
        formikSetFieldValue('linkedin_text_value', value);
        calculateMetrics(value);
    };



    const handleChildChange = (event, newValue) => {
        formikSetFieldValue('linkedin_budget', newValue);
        calculateMetrics(newValue);

        formikSetFieldValue('linkedin_text_value', newValue);
        calculateMetrics(newValue);
    };

    let countryName = '';
    let city = '';
    let state = '';


    if (sociallinkedincampigndetaillist[0]?.linkedin_ads_reach_location) {
        try {
            // Parse the JSON string into a JavaScript array of objects
            const data = JSON.parse(sociallinkedincampigndetaillist[0].linkedin_ads_reach_location);

            // Extract data from the first object if available
            if (Array.isArray(data) && data.length > 0) {
                countryName = data[0].f_country_name || '';
                city = data[0].f_name || '';  // Assuming f_name is the field for city
                state = data[0].f_region || '';
            }
        } catch (error) {
            console.error('Error parsing JSON:', error);
        }
    }
    return (
        <>
            {sociallinkedincampigndetaillist && sociallinkedincampigndetaillist.length > 0 &&
                sociallinkedincampigndetaillist.map((linkedin, index) => (
                    <Grid sx={styleCss.CampaignBox}>
                        <Typography sx={{
                            fontFamily: 'Inter',
                            fontSize: '14px',
                            fontWeight: 'normal',
                            fontStretch: 'normal',
                            fontStyle: 'normal',
                            lineHeight: 'normal',
                            letterSpacing: 'normal',
                            textAlign: 'left',
                            color: '#555',
                            marginTop: '10px',
                            marginBottom: '10px',
                        }}>Campaign Objective</Typography>
                        <Typography sx={{ fontSize: '16px', fontWeight: 'bold', marginBottom: '1px', }}>
                            Traffic
                        </Typography>
                        <Typography sx={{
                            fontFamily: 'Inter',
                            fontSize: '12px',
                            fontWeight: 'normal',
                            fontStretch: 'normal',
                            fontStyle: 'normal',
                            lineHeight: 'normal',
                            letterSpacing: 'normal',
                            textAlign: 'left',
                            color: '#000',
                        }}>
                            Drive visitors to your website or marketing landing pages.
                        </Typography>
                        <Grid sx={{
                            padding: '12px 20px',
                            backgroundColor: '#0077B5',
                            marginTop: '15px'
                        }}>
                            <Typography sx={{
                                fontFamily: 'Inter',
                                fontSize: '14px',
                                fontWeight: 500,
                                fontStretch: 'normal',
                                fontStyle: 'normal',
                                lineHeight: 'normal',
                                letterSpacing: 'normal',
                                textAlign: 'left',
                                color: '#fff',
                            }}><img src={Campaign2} style={{
                                verticalAlign: 'middle', marginRight: '10px',
                            }} /> {linkedin.linkedin_campaign_name}</Typography>
                        </Grid>
                        <Grid sx={{ textAlign: 'center', marginTop: '15px', marginBottom: '12px' }}>
                            <PieChart
                                colors={palette}
                                series={[{
                                    data: [
                                        { name: 'Estimated Click', value: result.totalClicks },
                                        { name: 'Estimated View', value: result.totalLeads }
                                    ]
                                }]}

                                {...pieParams}
                            />
                        </Grid>
                        {/* <Grid container>
                            <Grid md={6} xs={6}>
                                <Typography sx={{ fontSize: '12px', fontWeight: 'normal', color: '#000', textAlign: 'center', fontFamily: 'Inter' }}>
                                    Appro.  Reach
                                </Typography>
                                <Typography sx={{
                                    fontSize: '18px',
                                    fontWeight: 'bold',
                                    fontStretch: 'normal',
                                    fontStyle: 'normal',
                                    lineHeight: 'normal',
                                    letterSpacing: 'normal',
                                    textAlign: 'center',
                                    color: '#0077B5',
                                    fontFamily: 'Inter',
                                    marginTop: '5px'
                                }}>
                                    32.0k to 93.2k
                                </Typography>
                            </Grid>
                            <Grid md={6} xs={6}>
                                <Typography sx={{ fontSize: '12px', fontWeight: 'normal', color: '#000', textAlign: 'center', fontFamily: 'Inter' }}>
                                    Appro. Page Like
                                </Typography>
                                <Typography sx={{
                                    fontSize: '18px',
                                    fontWeight: 'bold',
                                    fontStretch: 'normal',
                                    fontStyle: 'normal',
                                    lineHeight: 'normal',
                                    letterSpacing: 'normal',
                                    textAlign: 'center',
                                    color: '#0077B5',
                                    fontFamily: 'Inter',
                                    marginTop: '5px'
                                }}>
                                    357.1k
                                </Typography>
                            </Grid>
                        </Grid> */}
                        <Grid md={12}>
                            <Typography sx={{
                                fontSize: '14px',
                                fontWeight: 'normal',
                                fontStretch: 'normal',
                                fontStyle: 'normal',
                                lineHeight: 'normal',
                                letterSpacing: 'normal',
                                textAlign: 'left',
                                color: '#555',
                                marginTop: '20px',
                                fontFamily: 'Inter',
                            }}>Budget</Typography>

                            <Box sx={{ width: '100%', marginTop: '10px' }}>
                                <SliderRange
                                    valueLabelDisplay="auto"
                                    aria-labelledby="range-slider-facebook"
                                    name="linkedin_budget"
                                    value={formikValues.linkedin_budget}
                                    onChange={handleChildChange}
                                    //onChange={(event, newValue) => formikValues('whatsapp_budget', newValue)}
                                    min={0}
                                    max={100000}
                                    sx={{
                                        '& .MuiSlider-thumb': {
                                            color: 'linear-gradient(45deg, #4285F4, #DB4437, #F4B400, #0F9D58)',
                                        },
                                        '& .MuiSlider-track': {
                                            bgcolor: 'linear-gradient(45deg, #4285F4, #DB4437, #F4B400, #0F9D58)',
                                            border: '1px solid transparent',
                                        },
                                        '& .MuiSlider-rail': {
                                            bgcolor: 'linear-gradient(45deg, #4285F4, #DB4437, #F4B400, #0F9D58)',
                                        },

                                    }}
                                />
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography
                                        variant="body2"
                                        onClick={() => setSlideVal(MIN)}
                                        sx={{ cursor: 'pointer', color: '#555', fontSize: '16px', fontFamily: 'Inter' }}
                                    >
                                        {formikValues.linkedin_budget}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        onClick={() => setSlideVal(MAX)}
                                        sx={{ cursor: 'pointer', color: '#555', fontSize: '16px', fontFamily: 'Inter' }}
                                    >
                                        {MAX}
                                    </Typography>
                                </Box>

                                <TextField
                                    type="number"
                                    name="linkedin_text_value"
                                    label="Amount"
                                    value={formikValues.linkedin_text_value}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        formikSetFieldValue('linkedin_text_value', parseInt(value));
                                        formikSetFieldValue('linkedin_budget', parseInt(value));
                                        calculateMetrics(parseInt(value))
                                    }}
                                    sx={{ mt: 2, width: '100%' }}
                                />
                            </Box>
                        </Grid>
                        <Grid md={12} sx={{ borderBottom: '1px solid #d6d6d0', marginTop: '15px', marginBottom: '15px' }}></Grid>

                        <Grid md={12}>
                            <Typography sx={{
                                fontSize: '14px',
                                fontWeight: 'bold',
                                textAlign: 'center',
                                color: '#555',
                                marginTop: '20px',
                                fontFamily: 'Inter',
                                marginTop: '12px'
                            }}>
                                {linkedin?.linkedin_campaign_name}
                            </Typography>
                        </Grid>

                        <Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: '#000', fontFamily: 'Inter', textAlign: 'center', }}>
                            Account Name :  {linkedin?.name}
                        </Typography>

                        <Grid md={12}>
                            <Typography sx={{ fontSize: '14px', fontWeight: 'normal', color: '#000', fontFamily: 'Inter', textAlign: 'center', }}>
                                Objective : {linkedin?.campaign_objective}
                            </Typography>
                        </Grid>
                        <Grid md={12}>
                            <Typography sx={{ fontSize: '14px', fontWeight: 'normal', color: '#000', fontFamily: 'Inter', textAlign: 'center', }}>
                                Website link : {linkedin?.linkedin_ads_website_url}
                            </Typography>
                        </Grid>

                        <Grid md={12} sx={{ borderBottom: '1px solid #d6d6d0', marginTop: '15px', marginBottom: '15px' }}></Grid>
                        <Grid container>

                            <Grid md={3} xs={3}>
                                <Grid sx={{
                                    padding: '10px',
                                    borderRadius: '30px',
                                    backgroundColor: '#0077B5',
                                    width: '40px',
                                    height: '40px',
                                    display: 'grid',
                                    placeContent: 'center',
                                    marginBottom: '8px'
                                }}>
                                    <img src={LocationIcon3} style={{ width: '19px' }} />
                                </Grid>
                            </Grid>
                            <Grid md={3} xs={3}>
                                <Typography sx={{
                                    fontSize: '11px',
                                    fontWeight: 'normal',
                                    fontStretch: 'normal',
                                    fontStyle: 'normal',
                                    lineHeight: 'normal',
                                    letterSpacing: 'normal',
                                    textAlign: 'left',
                                    color: '#555',
                                    fontFamily: 'Inter',
                                }}>Estimated Click</Typography>

                                <Typography sx={{
                                    fontFamily: 'Inter',
                                    fontSize: '14px',
                                    color: '#000',
                                    paddingTop: '4px',
                                    marginBottom: '20px'
                                }}>
                                    {Math.floor(result.totalClicks)}
                                </Typography>
                            </Grid>

                            <Grid md={3} xs={3} >
                                <Grid sx={{
                                    padding: '10px',
                                    borderRadius: '30px',
                                    backgroundColor: '#0077B5',
                                    width: '40px',
                                    height: '40px',
                                    display: 'grid',
                                    placeContent: 'center',
                                    marginBottom: '8px'
                                }}>
                                    <img src={LocationIcon3} style={{ width: '19px' }} />
                                </Grid>
                            </Grid>
                            <Grid md={3} xs={3}>
                                <Typography sx={{
                                    fontSize: '11px',
                                    fontWeight: 'normal',
                                    fontStretch: 'normal',
                                    fontStyle: 'normal',
                                    lineHeight: 'normal',
                                    letterSpacing: 'normal',
                                    textAlign: 'left',
                                    color: '#555',
                                    fontFamily: 'Inter',
                                }}>Estimated  Leads</Typography>

                                <Typography sx={{
                                    fontFamily: 'Inter',
                                    fontSize: '14px',
                                    color: '#000',
                                    paddingTop: '4px'
                                }}>
                                    {Math.floor(result.totalLeads)}
                                </Typography>
                            </Grid>


                            <Grid md={3} xs={3}>
                                <Grid sx={{
                                    padding: '10px',
                                    borderRadius: '30px',
                                    backgroundColor: '#0077B5',
                                    width: '40px',
                                    height: '40px',
                                    display: 'grid',
                                    placeContent: 'center',
                                    marginBottom: '8px'
                                }}>
                                    <img src={LocationIcon3} style={{ width: '19px' }} />
                                </Grid>
                            </Grid>
                            <Grid md={3} xs={3}>
                                <Typography sx={{
                                    fontSize: '11px',
                                    fontWeight: 'normal',
                                    fontStretch: 'normal',
                                    fontStyle: 'normal',
                                    lineHeight: 'normal',
                                    letterSpacing: 'normal',
                                    textAlign: 'left',
                                    color: '#555',
                                    fontFamily: 'Inter',
                                }}>Estimated Impression</Typography>

                                <Typography sx={{
                                    fontFamily: 'Inter',
                                    fontSize: '14px',
                                    color: '#000',
                                    paddingTop: '4px',
                                    marginBottom: '20px'
                                }}>
                                    {Math.floor(result.totalImpressions)}
                                </Typography>
                            </Grid>

                            <Grid md={3} xs={3} >
                                <Grid sx={{
                                    padding: '10px',
                                    borderRadius: '30px',
                                    backgroundColor: '#0077B5',
                                    width: '40px',
                                    height: '40px',
                                    display: 'grid',
                                    placeContent: 'center',
                                    marginBottom: '8px'
                                }}>
                                    <img src={LocationIcon3} style={{ width: '19px' }} />
                                </Grid>
                            </Grid>
                            <Grid md={3} xs={3}>
                                <Typography sx={{
                                    fontSize: '11px',
                                    fontWeight: 'normal',
                                    fontStretch: 'normal',
                                    fontStyle: 'normal',
                                    lineHeight: 'normal',
                                    letterSpacing: 'normal',
                                    textAlign: 'left',
                                    color: '#555',
                                    fontFamily: 'Inter',
                                }}>Expected  Conversions</Typography>

                                <Typography sx={{
                                    fontFamily: 'Inter',
                                    fontSize: '14px',
                                    color: '#000',
                                    paddingTop: '4px'
                                }}>
                                    {Math.floor(result.totalConversions)}
                                </Typography>
                            </Grid>

                            <Grid md={3} xs={3}>
                                <Grid sx={{
                                    padding: '10px',
                                    borderRadius: '30px',
                                    backgroundColor: '#0077B5',
                                    width: '40px',
                                    height: '40px',
                                    display: 'grid',
                                    placeContent: 'center',
                                    marginBottom: '8px'
                                }}>
                                    <img src={LocationIcon1} style={{ width: '19px' }} />
                                </Grid>
                            </Grid>

                            <Grid md={3} xs={3}>
                                <Typography sx={{
                                    fontSize: '15px',
                                    fontWeight: 'normal',
                                    fontStretch: 'normal',
                                    fontStyle: 'normal',
                                    lineHeight: 'normal',
                                    letterSpacing: 'normal',
                                    textAlign: 'left',
                                    color: '#555',
                                    fontFamily: 'Inter',
                                }}>Location</Typography>
                                <Typography sx={{
                                    fontFamily: 'Inter',
                                    fontSize: '14px',
                                    color: '#000',
                                    paddingTop: '4px'
                                }}>
                                    {city}
                                </Typography>
                                <Typography sx={{
                                    fontFamily: 'Inter',
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                    textAlign: 'left',
                                    color: '#000',
                                }}>
                                    {state}
                                </Typography>
                                <Typography sx={{
                                    fontFamily: 'Inter',
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                    textAlign: 'left',
                                    color: '#000',
                                }}>
                                    {countryName}
                                </Typography>
                            </Grid>


                            <Grid md={3} xs={3} sx={{ textAlign: 'center', marginTop: '0px' }}>
                                <Grid sx={{
                                    padding: '10px',
                                    borderRadius: '30px',
                                    backgroundColor: '#0077B5',
                                    width: '40px',
                                    height: '40px',
                                    display: 'grid',
                                    placeContent: 'center',
                                    marginBottom: '8px'
                                }}>
                                    <img src={LocationIcon2} style={{ width: '12px' }} />
                                </Grid>
                            </Grid>

                            <Grid md={3} xs={3} sx={{ textAlign: 'center', marginTop: '0px' }}>
                                <Typography sx={{
                                    fontSize: '15px',
                                    fontWeight: 'normal',
                                    fontStretch: 'normal',
                                    fontStyle: 'normal',
                                    lineHeight: 'normal',
                                    letterSpacing: 'normal',
                                    textAlign: 'left',
                                    color: '#555',
                                    fontFamily: 'Inter',
                                }}>Gender</Typography>
                                <Typography sx={{
                                    fontFamily: 'Inter',
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                    textAlign: 'left',
                                    color: '#000',
                                }}>
                                    All
                                </Typography>
                            </Grid>

                            <Grid md={3} xs={3} sx={{ marginTop: '20px' }}>
                                <Grid sx={{
                                    padding: '10px',
                                    borderRadius: '30px',
                                    backgroundColor: '#0077B5',
                                    width: '40px',
                                    height: '40px',
                                    display: 'grid',
                                    placeContent: 'center',
                                    marginBottom: '8px'
                                }}>
                                    <img src={LocationIcon1} style={{ width: '19px' }} />
                                </Grid>
                            </Grid>

                            <Grid md={3} xs={3} sx={{ marginTop: '20px' }}>

                                <Typography sx={{
                                    fontFamily: 'Inter',
                                    fontSize: '12px',
                                    fontWeight: 'bold',
                                    textAlign: 'left',
                                    color: '#000',
                                }}>
                                    Budget
                                </Typography>
                                <Typography sx={{
                                    fontFamily: 'Inter',
                                    fontSize: '12px',
                                    fontWeight: 'bold',
                                    textAlign: 'left',
                                    color: '#000',
                                }}>
                                    {formikValues.linkedin_text_value}
                                </Typography>
                            </Grid>
                        </Grid>

                    </Grid>
                ))}
        </>
    )
}

export default LinkedInCampaign