import {
  Radio, RadioGroup, Grid, Switch, Card, Tooltip, Box, FormLabel, Typography, TextField, Table, Checkbox, TableBody, Button, TableCell, TableContainer, TableHead, TableRow, Paper, InputLabel, MenuItem, FormControl, FormControlLabel, Select,
} from "@mui/material";

import styleCss from "../Style/style"
import React, { useState, useEffect } from "react";
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import { Formik, Form, Field, useFormik, ErrorMessage } from 'formik';

import * as Yup from 'yup';
import { authpostAxios } from "../Utilities/commonAxios";
import { useAutocomplete } from '@mui/base/useAutocomplete';
import { styled } from '@mui/system';
import Select2 from 'react-select';
import Avatar from '@mui/material/Avatar';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import CommentIcon from '@mui/icons-material/Comment';
import ShareIcon from '@mui/icons-material/Share';
import { fileToBase64 } from "../Utilities/fileConversion";
import Swal from 'sweetalert2';
import '../Style/Loader.css'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs'; // Import Dayjs
import 'dayjs/locale/en'; 
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';


const initialValues = {
  ytube_ads_account_detail: '',
  campagin_name: '',
  goal_mode: '',
  gender_list: '',
  is_spl_category: false,
  spl_ad_category_list: '',
  location_details: [],
  ad_name: '',
  ad_content: '',
  ad_headline: '',
  ad_website_url: '',
  call_to_action: '',
  ad_image_upload: '',
};
const validationSchema = Yup.object({

  ytube_ads_account_detail: Yup.string().required('Select the Ad Account'),
  campagin_name: Yup.string().required('Please enter Campagin Name'),
  goal_mode: Yup.string().required('Select the Goal'),
  location_details: Yup.array()
  .min(1, 'Please select at least one location detail')
  .required('Location details are required'),
  gender_list: Yup.string().required('Please select any one'),
  ad_name:Yup.string().required('Please enter ad name'),
  ad_headline:Yup.string().required('Please enter ad headline'),
  ad_website_url:Yup.string().required('Please add website url'),
  ad_image_upload:Yup.string().required('Please upload ad image'),
  call_to_action:Yup.string().required('Please select the action'),
  ad_content:Yup.string().required('Please enter the ad content'),
  
});


const Ytubeadsadbuget = () => {

  const [value, setValue] = React.useState('');
  const [selectedOptionText, setSelectedOptionText] = useState('');
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [adimage, setAd_image] = useState();

  const handleFileChange = (e) => {
    const file = e.currentTarget.files[0];
    if (file) {
      submitSocialForm.setFieldValue('ad_image_upload', file);
    }
  };

  const handlevideoFileChange = (e) => {
    const file = e.currentTarget.files[0];
    if (file) {
      submitSocialForm.setFieldValue('ad_video_upload', file);
    }
  };
  

  const renderImage = () => {
    if (submitSocialForm.values.ad_image_upload) {
      const url = URL.createObjectURL(submitSocialForm.values.ad_image_upload);
      return (
        <img
          src={url}
          alt="image"
          style={{
            width: '70px',
            minHeight: '70px', // Setting minimum height
            overflow: 'hidden',
            objectFit: 'cover',
            textAlign: 'center',
            objectPosition: 'top',
            marginTop: '10px'
          }}
        />
      );
    }
    return null;
  };

  const renderVideo = () => {
    if (submitSocialForm.values.ad_video_upload) {
      const url = URL.createObjectURL(submitSocialForm.values.ad_video_upload);
      return (
        <video
        src={url}
        controls
        style={{
          width: '100%', // Adjust width as needed
          minHeight: '70px', // Setting minimum height
          marginTop: '10px'
        }}
      >
        Your browser does not support the video tag.
      </video>
      );
    }
    return null;
  };


  const handleChange = (event) => {
    setValue(event.target.value);
    const selectedText = event.target.options[event.target.selectedIndex].text; // Get selected text
    setSelectedOptionText(selectedText);
    setSelectedOptions(event);
  };


  //console.log(selectedOptionText);
  const option_list = [
    { value: 1, label: 'Learn more' },
    { value: 2, label: 'Apply now' },
    { value: 3, label: 'Book now' },
    { value: 4, label: 'Contact Us' },
    { value: 5, label: 'Download' },
    { value: 6, label: 'Get offer' },
    { value: 7, label: 'Get quote' },
  ];



  const url = 'http://example.com/';

  // Function to extract domain from URL
  const getDomainFromUrl = (url) => {
    const hostname = new URL(url).hostname;
    return hostname.replace(/^www\./, ''); // Remove 'www.' if present
  };


  const [isSwitchOn, setIsSwitchOn] = useState('');
  const handleSwitchToggle = () => {
    setIsSwitchOn(prevState => !prevState);
  };

  const [options, setOptions] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (value) => {
    setInputValue(value); // Set input value state
    loadOptions(value); // Call loadOptions with the input value
  };
  const loadOptions = async (inputValue) => {
    setIsLoading(true);
    try {
      const response = await authpostAxios({
        url: `${process.env.REACT_APP_BACKENDURL}api/get-search-country-region`,
        data: {
          searchRegionName: inputValue,
          // Add other parameters as needed
        }
      });
      setOptions(response.data?.data || []);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data: ", error);
      setIsLoading(false);
    }
  };


  const [loading, setLoading] = useState(false);

  const [options1, setOptions1] = useState([]);
  const [isLoading1, setIsLoading1] = useState(false);
  const [inputValue1, setInputValue1] = useState('');
  const handleInputChange1 = (value) => {
    setInputValue1(value); // Set input value state
    loadOptions1(value); // Call loadOptions with the input value
  };
  const loadOptions1 = async (inputValue1) => {
    setIsLoading1(true);
    try {
      const response = await authpostAxios({
        url: `${process.env.REACT_APP_BACKENDURL}api/get-search-target`,
        data: {
          searchTargetName: inputValue1,
          // Add other parameters as needed
        }
      });
      setOptions1(response.data?.data || []);
      setIsLoading1(false);
    } catch (error) {
      console.error("Error fetching data: ", error);
      setIsLoading1(false);
    }
  };

  const handleDateChangeto = (newDate) => {
    submitSocialForm.setFieldValue('to_date', newDate); // Update the form field value
  };

  const handleDateChangefrom = (newDate) => {
    submitSocialForm.setFieldValue('from_date', newDate); // Update the form field value
  };


  const goalModeText = {
    grow_customer_base: 'Grow customer base',
    get_more_website_visitors: 'Get more website visitors',
    // Add more mappings as needed
  };
    
  const [ytubeaccList, setYtubeaccList] = useState([]);
  const getytubeaccListFromAPI = async () => {
    try {
      const response = await authpostAxios({
        url: `${process.env.REACT_APP_BACKENDURL}api/get-youtube-emailid`,
        data: {  }
      });

      setYtubeaccList(response.data?.message || []);
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };



  const submitSocialForm = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      if (!submitSocialForm.dirty) {
      } else {
        setLoading(true);
        Swal.fire({
          icon: 'question',
          title: 'Confirm Submission',
          text: 'Are you sure you want to save the Facebook campaign?',
          showCancelButton: true,
          confirmButtonText: 'Yes, save it!',
          cancelButtonText: 'No, cancel',
        }).then(async (result) => {
          if (result.isConfirmed) {
            // User confirmed, proceed with form submission
            try {
              let formData = {
                ytube_ads_account_detail: values.ytube_ads_account_detail,
                campagin_name: values.campagin_name,
                goal_mode: values.goal_mode,
                gender_list: values.gender_list,
                is_spl_category: isSwitchOn,
                spl_ad_category_list: values.spl_ad_category_list,
                location_details: values.location_details,
                detailed_targeting_listing: values.detailed_targeting_listing,
                ad_name: values.ad_name,
                ad_content: values.ad_content,
                ad_headline: values.ad_headline,
                ad_website_url: values.ad_website_url,
                call_to_action: values.call_to_action,
                ad_image_upload: values.ad_image_upload === "" ? "" : await fileToBase64(values.ad_image_upload),
                //ad_video_upload: values.ad_video_upload === "" ? "" : await fileToBase64(values.ad_video_upload),
              };

              let response = await authpostAxios({
                url: `${process.env.REACT_APP_BACKENDURL}api/youtube-budget-analysis`,
                data: formData,
              });
              console.log(response.data);
              if (response.data.flag === 1) {
                setLoading(false);
                Swal.fire({
                  icon: 'success',
                  title: 'Form Submitted Successfully!',
                  text: 'Your data has been submitted.',
                }).then(() => {
                  resetForm();
                });
              } else {
                // Handle other cases if needed
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'Failed to submit the form. Please try again later.',
                });
              }
            } catch (error) {
              console.error('Error submitting form:', error);
              // Handle error accordingly
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Failed to submit the form. Please try again later.',
              });
            }
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            // User cancelled, do nothing or provide feedback
            Swal.fire({
              icon: 'info',
              title: 'Cancelled',
              text: 'The form submission was cancelled.',
            });
          }
        });

      }
    },
  });

  console.log(ytubeaccList);
  useEffect(() => {
    console.log('Effect ran');
    getytubeaccListFromAPI();
  }, []);
  return (

  <Grid container style={styleCss.commonpaddingtop}>
      <Grid item xs={6}>
  <h2 style={{ textAlign: 'left' }}>Youtube & Google Ads Campaign</h2>
</Grid>
<Grid item xs={6} style={{ textAlign: 'right' }}>
<Link to="/socialmediaadlisting" style={{ textDecoration: 'none' }}>
  <Button variant="contained" startIcon={<AddIcon />}>
    Listing
  </Button>
</Link>
</Grid>
      <Grid container xs={9} sx={{ border: '1px solid f1f1f1', backgroundColor: '#f0f0f0', padding: 2 }}>
        <form onSubmit={submitSocialForm.handleSubmit}>
          <Grid container xs={12} spacing={2}>
            <Grid item xs={4} spacing={2}>
              <FormControl fullWidth>
                <InputLabel id="select-label">Social Ad Account</InputLabel>
                <Select
                  labelId="select-label"
                  id="ytube_ads_account_detail"
                  name="ytube_ads_account_detail"
                  value={submitSocialForm.values.ytube_ads_account_detail}
                  onChange={submitSocialForm.handleChange}
                  label="Social Ad Account"
                >
                  {ytubeaccList.length > 0 ? (
                    ytubeaccList.map((item) => (
                      <MenuItem key={item.social_auth_user_id} value={item.social_auth_user_id}>
                        {item.screen_name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No Account Available</MenuItem>
                  )}
                </Select>
              </FormControl>
              <Grid sx={{ color: 'red', fontSize: '12px', fontWeight: 'bold' }}>{submitSocialForm.errors.ytube_ads_account_detail}</Grid>
            </Grid>
            <Grid item xs={8}>
              <TextField
                fullWidth
                label="Campagin Name"
                id="campagin_name"
                name="campagin_name"
                onChange={submitSocialForm.handleChange}
                value={submitSocialForm.values.campagin_name}
              />
                <Grid sx={{ color: 'red', fontSize: '12px', fontWeight: 'bold' }}>{submitSocialForm.errors.campagin_name}</Grid>
            </Grid>
          
          </Grid>

         

          <Grid container xs={12} sx={{ marginTop: 4 }}>
            <Box>
              <Typography variant="h6" sx={{ color: 'blue', marginBottom: 1 }}>
                Campaign objective
              </Typography>
              <Typography variant="body1" sx={{ marginTop: 1 }}>
                Your campaign objective is the business outcome you want to achieve with your campaign
              </Typography>
            </Box>
          </Grid>

          <Grid container xs={12} sx={{ marginTop: 2 }}>
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Select a campaign objective
              </FormLabel>

              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="goal_mode" // Maintain this name for FormKit integration
                value={submitSocialForm.values.goal_mode} // Bind value to Formik state
                onChange={submitSocialForm.handleChange} // Leverage Formik's onChange handler
              >
               {/* <FormControlLabel value="get_page_like" control={<Radio />} label="Get more Page likes" /> /*/}
                <FormControlLabel value="grow_customer_base" control={<Radio />} label="Grow customer base" /> 
                {/* <FormControlLabel value="get_more_messages" control={<Radio />} label="Get more messages" />*/}
                {/* <FormControlLabel value="getmore_calls" control={<Radio />} label="Get more calls" />*/}
                {/* <FormControlLabel value="promote_your_business_locally" control={<Radio />} label="Promote your business locally" />*/}
                <FormControlLabel value="get_more_website_visitors" control={<Radio />} label="Get more website visitors" />
               
              </RadioGroup>
              <Grid sx={{ color: 'red', fontSize: '12px', fontWeight: 'bold' }}>{submitSocialForm.errors.goal_mode}</Grid>
            </FormControl>
          </Grid>
 
          <Grid container xs={12} sx={{ marginTop: 1 }}>
            <Box>
              <Typography variant="h6" sx={{ color: 'blue', marginBottom: 1 }}>
                Decide on your audience and budget
              </Typography>
              <Typography variant="body1" sx={{ marginTop: 1 }}>
                If you're based in or targeting an audience in the United States and your ad relates to credit, employment, or housing, you must identify it to comply with Facebook's advertising policies. Special ad categories have restricted targeting options.
              </Typography>
            </Box>
          </Grid>

          <Grid container xs={12} sx={{ marginTop: 2 }}>
            <Grid item xs={6}>
              <Select2
                options={options}
                getOptionLabel={(option) => {
                  let label = `${option.f_name}`;
                  if (option.f_region && option.f_country_name) {
                    label += ` - ${option.f_region} - ${option.f_country_name}`;
                  } else if (option.f_region) {
                    label += ` - ${option.f_region}`;
                  } else if (option.f_country_name) {
                    label += ` - ${option.f_country_name}`;
                  }
                  return label;
                }}
                getOptionValue={(option) => option.facebook_country_targeting_search_id}
                isMulti
                placeholder="Search..."
                isLoading={isLoading}
                value={submitSocialForm.values.location_details.map(option => ({
                  facebook_country_targeting_search_id: option.facebook_country_targeting_search_id,
                  f_name: option.f_name,
                  f_region: option.f_region,
                  f_country_name: option.f_country_name,
                }))}
                onInputChange={handleInputChange}
                loadingMessage={() => 'Loading options...'}
                noOptionsMessage={() => 'No options found'}
                closeMenuOnSelect={false}
                name="location_details"
                onChange={(selectedOptions) => submitSocialForm.setFieldValue('location_details', selectedOptions)}
              />
               <Grid sx={{ color: 'red', fontSize: '12px', fontWeight: 'bold' }}>{submitSocialForm.errors.location_details}</Grid>
            </Grid>
          </Grid>

   

          <Grid container xs={12} sx={{ marginTop: 2 }}>
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="gender_list"
                value={submitSocialForm.values.gender_list}
                onChange={submitSocialForm.handleChange}
              >
                <FormControlLabel value="all" control={<Radio />} label="All" />
                <FormControlLabel value="female" control={<Radio />} label="Female" />
                <FormControlLabel value="male" control={<Radio />} label="Male" />
              </RadioGroup>
            </FormControl>
           
          </Grid>
          <Grid sx={{ color: 'red', fontSize: '12px', fontWeight: 'bold' }}>{submitSocialForm.errors.gender_list}</Grid>
   
          <Grid container xs={12} sx={{ marginTop: 2 }}>
            <Box>
              <Typography variant="h6" sx={{ color: 'blue', marginBottom: 1 }}>
                New Ads
              </Typography>
            </Box>
          </Grid>

        <Grid container spacing={1} sx={{ marginTop: 1 }}>
          <Grid item xs={6}>
              <TextField
                fullWidth
                label="Ad of the Name"
                id="ad_name"
                name="ad_name"
                onChange={submitSocialForm.handleChange}
                value={submitSocialForm.values.ad_name}
              />
               <Grid sx={{ color: 'red', fontSize: '12px', fontWeight: 'bold' }}>{submitSocialForm.errors.ad_name}</Grid>
            </Grid>
           
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Headline"
                id="ad_headline"
                name="ad_headline"
                onChange={submitSocialForm.handleChange}
                value={submitSocialForm.values.ad_headline}
              />
               <Grid sx={{ color: 'red', fontSize: '12px', fontWeight: 'bold' }}>{submitSocialForm.errors.ad_headline}</Grid>
            </Grid>
           
           </Grid> 

          <Grid container xs={12} spacing={2} sx={{ marginTop: 2 }}>
            <Grid item xs={6}>
              <TextField
                label="Content of ad"
                id="ad_content"
                name="ad_content"
                onChange={submitSocialForm.handleChange}
                value={submitSocialForm.values.ad_content}
                fullWidth
                multiline
                rows={6}
              />
              <Grid sx={{ color: 'red', fontSize: '12px', fontWeight: 'bold' }}>{submitSocialForm.errors.ad_content}</Grid>
            </Grid>
            
            <Grid item xs={3}>
              <input
                type="file"
                name="ad_image_upload"
                id="ad_image_upload"
                style={{ display: 'none' }}
                accept="image/jpg,image/jpeg,image/png"
                onChange={handleFileChange}
              />
              <label htmlFor="ad_image_upload">
                <Button
                  component="span"
                  fullWidth
                  variant="outlined"
                  style={{
                    border: '1px solid #cfd7df',
                    color: '#92a2b1',
                    textTransform: 'capitalize',
                    fontSize: '15px'
                  }}
                >
                  Choose JPG or PNG file
                </Button>
              </label>
              {renderImage()}
              <Grid sx={{ color: 'red', fontSize: '12px', fontWeight: 'bold' }}>{submitSocialForm.errors.ad_image_upload}</Grid>
            </Grid>
            {/*      
            <Grid item xs={3}>
            <input
              type="file"
              name="ad_video_upload"
              id="ad_video_upload"
              style={{ display: 'none' }} 
              accept="video/mp4,video/x-m4v,video/*" // Accepting video formats
              onChange={handlevideoFileChange}
            />
            <label htmlFor="ad_video_upload">
              <Button
                component="span"
                fullWidth
                variant="outlined"
                style={{
                  border: '1px solid #cfd7df',
                  color: '#92a2b1',
                  textTransform: 'capitalize',
                  fontSize: '15px'
                }}
              >
                Choose MP4 or other video file
              </Button>
            </label>
            {renderVideo()} 
            <Grid sx={{ color: 'red', fontSize: '12px', fontWeight: 'bold' }}>
              {submitSocialForm.errors.ad_video_upload}
            </Grid>
          </Grid>
          */}
           
          </Grid>

          <Grid container spacing={1} sx={{ marginTop: 1 }}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Website URL"
                id="ad_website_url"
                name="ad_website_url"
                onChange={submitSocialForm.handleChange}
                value={submitSocialForm.values.ad_website_url}
              />
               <Grid sx={{ color: 'red', fontSize: '12px', fontWeight: 'bold' }}>{submitSocialForm.errors.ad_website_url}</Grid>
            </Grid>
           
            <Grid item xs={6} spacing={2}>
            <FormControl fullWidth>
              <InputLabel id="select-label">Call to Action</InputLabel>
              <Select
                labelId="select-label"
                id="demo-simple-select"
                label="Call to Action"
                name="call_to_action"
                value={submitSocialForm.values.call_to_action}
                onChange={submitSocialForm.handleChange}
              >
                {option_list.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              <Grid sx={{ color: 'red', fontSize: '12px', fontWeight: 'bold' }}>{submitSocialForm.errors.call_to_action}</Grid>
            </FormControl>
           </Grid>
           </Grid>
          

          <Button type="submit" variant="contained" color="primary" style={{ marginTop: '16px' }}>
            Save Campaign
          </Button>
        </form>
      </Grid>


      <Grid container xs={3} sx={{
        border: '1px solid #ccc', backgroundColor: '#fdfdfe', padding: 2, width: '100%', marginBottom: '20px'
      }}>
        <Grid sx={{
          width: '100%',
          position: 'sticky',
          top: '90px',
          height: '100vh',
        }}>
          <Card sx={{
          }}>
            <CardContent>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Avatar alt="Profile Picture" src="profile-picture.jpg" />
                <Typography variant="body1" style={{ marginLeft: '10px', fontSize: '12px' }}>
                  {ytubeaccList.find(item => item.screen_name === submitSocialForm.values.screen_name)?.account_name || 'N/A'}
                  <span style={{ fontSize: 'small' }}> Sponsored</span>
                </Typography>
                <Typography variant="caption" style={{ marginLeft: 'auto' }}>
                </Typography>
              </div>
              <Typography variant="body1" mt={2}>
               {/* {renderImagepreview()}  */}
              </Typography>
              <Typography variant="body1" mt={2}>
                {submitSocialForm.values.ad_content}
              </Typography>
              <div style={{ display: 'flex', alignItems: 'center', backgroundColor: '#ccc', padding: '10px' }}>
    
                <Typography variant="caption" style={{ marginLeft: 'auto' }}>
                  <Button variant="contained" color="primary" sx={{ backgroundColor: 'white', color: 'black' }}>
                  {option_list.find(item => item.value === submitSocialForm.values.call_to_action)?.label || 'N/A'}
                  </Button>
                </Typography>
              </div>
              <div style={{ display: 'flex', position: 'sticky', top: '0' }}>
                <IconButton aria-label="Like">
                  <Typography sx={{ fontSize: '13px', fontFamily: 'Inter' }}>
                    <ThumbUpIcon sx={{ width: '20px', marginRight: '5px', verticalAlign: 'middle' }} />
                    Like
                  </Typography>
                </IconButton>
                <IconButton aria-label="Comment">
                  <Typography sx={{ fontSize: '13px', fontFamily: 'Inter' }}>
                    <CommentIcon sx={{ width: '20px', marginRight: '5px', verticalAlign: 'middle' }} />
                    Comment
                  </Typography>
                </IconButton>
                <IconButton aria-label="Share">
                  <Typography sx={{ fontSize: '13px', fontFamily: 'Inter' }}>
                    <ShareIcon sx={{ width: '20px', marginRight: '5px', verticalAlign: 'middle' }} />
                    Share
                  </Typography>
                </IconButton>
              </div>

          <Box sx={{ textAlign: 'left'}}>
            <Typography sx={{fontSize: '12px' }}>
              Campaign Goal :  {goalModeText[submitSocialForm.values.goal_mode]}
            </Typography>
            {submitSocialForm.values.budget_amount && (
             <Typography sx={{fontSize: '12px' }}>
               Budget : {submitSocialForm.values.budget_amount}
            </Typography>
             )}
             {submitSocialForm.values.bid_amount && (
             <Typography sx={{fontSize: '12px' }}>
               Bid Amount : {submitSocialForm.values.bid_amount}
            </Typography>
             )}
            <Typography sx={{fontSize: '12px' }}>
               From Date : {dayjs(submitSocialForm.values.from_date).format('DD-MM-YYYY HH:mm')}
            </Typography>
            {submitSocialForm.values.to_date && (
            <Typography sx={{fontSize: '12px' }}>
              To Date: {dayjs(submitSocialForm.values.to_date).format('DD-MM-YYYY HH:mm')}
            </Typography>
            )}

          </Box>


            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Ytubeadsadbuget