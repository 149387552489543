import React, { useState, useEffect } from "react";
import { Box, Button, Grid, Typography, Card, CardContent, CardHeader, CardMedia, Stack, Avatar } from '@mui/material';
import Campaign1 from "../img/facebook-campaign.png"
import styleCss from "../Style/style"
import { PieChart } from '@mui/x-charts/PieChart';
import LocationIcon1 from '../img/camplocation-icon1.png'
import LocationIcon2 from '../img/camplocation-icon2.png'
import LocationIcon3 from '../img/camplocation-icon3.png'
import WhatsupHeadicon from '../img/whatsup-headicon.png'
import MailHeadicon from '../img/mail-headicon.png'
import MessageHeadicon from '../img/message-headicon.png'
import SmartToyIcon from '@mui/icons-material/SmartToy';
import VoiceChatIcon from '@mui/icons-material/VoiceChat';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { authpostAxios } from "../Utilities/commonAxios";
import moment from 'moment';
import IconButton from '@mui/material/IconButton';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import CommentIcon from '@mui/icons-material/Comment';
import ShareIcon from '@mui/icons-material/Share';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import PinterestIcon from '@mui/icons-material/Pinterest';
import GoogleIcon from '@mui/icons-material/Google';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Chart from 'react-apexcharts';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

import SmsIcon from '@mui/icons-material/Sms';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import DialpadIcon from '@mui/icons-material/Dialpad';

const fbAdsPosting = 0;
const pieParams = { height: 150, margin: { right: 5 } };
const palette1 = ['#1877F2', '#d9d9d9'];
const palette2 = ['#25D366', '#d9d9d9'];
const palette3 = ['#004f9f', '#d9d9d9'];
const palette4 = ['#4dacf4', '#d9d9d9'];
const palette5 = ['#167964', '#d9d9d9'];
const palette6 = ['#77943e', '#d9d9d9'];


const MAX = 10000;
const MIN = 0;
const marks = [
    {
        value: MIN,
        label: '',
    },
    {
        value: MAX,
        label: '',
    },
];

function PaiseToRupees(paise) {
    return paise / 100;
}
const BudgetlistView = () => {

    const socialMediaData = {
        facebook: {
            icon: <FacebookIcon color="primary" />,
            label: 'Likes',
            count: 5461,
            iconColor: '#fdfdfd', // Blue for Facebook
        },
        twitter: {
            icon: <TwitterIcon color="primary" />,
            label: 'Followers',
            count: 5461,
            iconColor: '#fdfdfd', // Blue for Twitter
        },
        instagram: {
            icon: <InstagramIcon color="secondary" />,
            label: 'Followers',
            count: 5461,
            iconColor: '#fdfdfd', // Pink for Instagram
        },
        youtube: {
            icon: <YouTubeIcon color="error" />,
            label: 'Subscribers',
            count: 5461,
            iconColor: '#fdfdfd', // Red for YouTube
        },
        pinterest: {
            icon: <PinterestIcon color="warning" />,
            label: 'Followers',
            count: 5461,
            iconColor: '#fdfdfd', // Red for Pinterest
        },
        linkedin: {
            icon: <LinkedInIcon color="info" />,
            label: 'Followers',
            count: 5461,
            iconColor: '#fdfdfd', // Blue for LinkedIn
        },

    }
    const { enbudgetid } = useParams()
    const budgetid = atob(enbudgetid);

    console.log(budgetid);


    const [detaillisting, setDetaillisting] = useState([]);

    const getdetaillistingFromAPI = async (budgetid) => {
        try {
            const response = await authpostAxios({
                url: `${process.env.REACT_APP_BACKENDURL}api/view-budget-estmation`,
                data: { budget_tracker_id: budgetid }
            });
            setDetaillisting(response.data?.data || []);
        } catch (error) {
            console.error("Error fetching data: ", error);
        }
    };


    console.log(detaillisting);
    useEffect(() => {
        getdetaillistingFromAPI(budgetid)

    }, []);

    let fb_ad_posting_id = "";
    let fb_budget_amount = "";
    let fb_campaign = "";
    let fb_screen_name = "";
    let fbcountryName = '';
    let fbcity = '';
    let fbstate = '';
    let fb_estimated_click = 0;
    let fb_estimated_lead = 0;
    let facebook_estimated_cpc = 0;
    let fb_profile = '';
    let fb_ads_headlines = '';
    let fbtube_ads_content = '';
    let fb_campaignobjective = '';
    let fb_start_date = '';
    let fbend_date = '';
    let fb_website_link = '';
    let fb_gender = '';

    let youtube_ad_posting_id = 0;
    let youtube_budget_amount = "";
    let youtube_campaign = "";
    let youtube_screen_name = "";
    let ycountryName = '';
    let ycity = '';
    let ystate = '';
    let youtube_estimated_click = 0;
    let youtube_estimated_view = 0;
    let youtube_estimated_conversion = 0;
    let youtube_expected_conversion = 0;
    let youtube_profile = '';
    let ytube_ads_headlines = '';
    let ytube_ads_content = '';
    let ytubecampaignobjective = '';
    let start_date = '';
    let end_date = '';

    let is_whatsapp_checked = '';
    let whatsapp_budget_amount = '';
    let cost_for_whatsapp = '';
    let approximate_whatsapp_send_message = '';
    let whatsapp_campaign_name = '';

    let is_mail_checked = "";
    let mail_campaign_name = '';
    let mail_budget_amount = '';
    let cost_for_mail = '';
    let approximate_mail_send_message = '';

    let approximate_sms_send_message = '';

    let linkedin_ad_posting_id = '';
    let linkedincampaignobjective = '';
    let linkedincountryName = '';
    let linkedincity = '';
    let linkedinstate = ''
    let linkedin_estimated_click = 0;
    let linkedin_estimated_leads = 0;
    let linkedin_estimated_impression = 0;
    let linkedin_expected_conversions = 0;


    if (detaillisting) {
        youtube_ad_posting_id = detaillisting.youtube_ad_posting_id;
        youtube_budget_amount = detaillisting.youtube_budget_amount;
        youtube_campaign = detaillisting.ytube_campaign_name;
        youtube_screen_name = detaillisting.screen_name;
        youtube_estimated_click = parseInt(detaillisting.youtube_estimated_click, 10);
        youtube_estimated_view = parseInt(detaillisting.youtube_estimated_view, 10);
        youtube_estimated_conversion = parseInt(detaillisting.youtube_estimated_conversion, 10);
        youtube_expected_conversion = parseInt(detaillisting.youtube_expected_conversion, 10);
        youtube_profile = detaillisting.youtube_profile;
        ytube_ads_headlines = detaillisting.ytube_ads_headlines;
        ytube_ads_content = detaillisting.ytube_ads_content;

        //facebook
        fb_ad_posting_id = detaillisting.facebook_ad_posting_id;
        fb_budget_amount = detaillisting.facebook_budget_amount;

        fb_campaign = detaillisting.fb_campaign_name;
        fb_screen_name = detaillisting.page_name;
        fb_estimated_click = detaillisting?.facebook_estimated_clicks;


        fb_estimated_lead = detaillisting.facebook_estimated_leads;
        facebook_estimated_cpc = parseInt(detaillisting.facebook_estimated_cpc, 10);
        fb_profile = detaillisting.page_profile_image;
        fb_ads_headlines = detaillisting.fb_ads_headlines;
        fbtube_ads_content = detaillisting.fb_ads_content;
        fb_website_link = detaillisting.fb_ads_website_url;
        fb_gender = detaillisting?.fb_targeting?.genders[0];


        start_date = moment(detaillisting.start_date).format('DD MMMM YYYY');
        end_date = moment(detaillisting.end_date).format('DD MMMM YYYY');


        is_whatsapp_checked = detaillisting.is_whatsapp_checked;
        whatsapp_budget_amount = parseInt(detaillisting.whatsapp_budget_amount, 10);
        cost_for_whatsapp = detaillisting.cost_for_whatsapp;
        whatsapp_campaign_name = detaillisting.whatsapp_campaign_name;
        approximate_whatsapp_send_message = parseInt(detaillisting.approximate_whatsapp_send_message, 10);


        is_mail_checked = detaillisting.is_mail_checked;
        mail_budget_amount = parseInt(detaillisting.mail_budget_amount, 10);
        cost_for_mail = detaillisting.cost_for_mail;
        mail_campaign_name = detaillisting.mail_campaign_name;
        approximate_mail_send_message = parseInt(detaillisting.approximate_mail_send_message, 10);

        linkedin_ad_posting_id = detaillisting.linkedin_ad_posting_id;
        linkedin_estimated_click = parseInt(detaillisting.linkedin_estimated_click, 10);
        linkedin_estimated_leads = parseInt(detaillisting.linkedin_estimated_leads, 10);
        linkedin_estimated_impression = parseInt(detaillisting.linkedin_estimated_impression, 10);
        linkedin_expected_conversions = parseInt(detaillisting.linkedin_expected_conversions, 10);



        if (detaillisting?.fb_ads_reach_location) {
            try {
                // Parse the JSON string into a JavaScript array of objects
                const data = JSON.parse(detaillisting.fb_ads_reach_location);

                // Extract data from the first object if available
                if (Array.isArray(data) && data.length > 0) {
                    ycountryName = data[0].f_country_name || '';
                    fbcity = data[0].f_name || '';  // Assuming f_name is the field for city
                    fbstate = data[0].f_region || '';
                }
            } catch (error) {
                console.error('Error parsing JSON:', error);
            }
        }

        if (detaillisting?.ytube_ads_reach_location) {
            try {
                // Parse the JSON string into a JavaScript array of objects
                const data = JSON.parse(detaillisting.ytube_ads_reach_location);

                // Extract data from the first object if available
                if (Array.isArray(data) && data.length > 0) {
                    fbcountryName = data[0].f_country_name || '';
                    ycity = data[0].f_name || '';  // Assuming f_name is the field for city
                    ystate = data[0].f_region || '';
                }
            } catch (error) {
                console.error('Error parsing JSON:', error);
            }
        }


        if (detaillisting?.linkedin_ads_reach_location) {
            try {
                // Parse the JSON string into a JavaScript array of objects
                const data = JSON.parse(detaillisting.linkedin_ads_reach_location);

                // Extract data from the first object if available
                if (Array.isArray(data) && data.length > 0) {
                    linkedincountryName = data[0].f_country_name || '';
                    linkedincity = data[0].f_name || '';  // Assuming f_name is the field for city
                    linkedinstate = data[0].f_region || '';
                }
            } catch (error) {
                console.error('Error parsing JSON:', error);
            }
        }

        if (detaillisting.linkedin_campaign_objective === 'grow_customer_base') {
            linkedincampaignobjective = 'Grow Customer Base';
        } else if (detaillisting.linkedin_campaign_objective === 'get_more_website_visitors') {
            linkedincampaignobjective = 'Website Visitors';
        }


        // Define outside the blocks

        if (detaillisting.ytube_campaign_objective === 'grow_customer_base') {
            ytubecampaignobjective = 'Grow Customer Base';
        } else if (detaillisting.ytube_campaign_objective === 'get_more_website_visitors') {
            ytubecampaignobjective = 'Website Visitors';
        }

        if (detaillisting.fb_campaign_objective === 'grow_customer_base') {
            fb_campaignobjective = 'Grow Customer Base';
        } else if (detaillisting.fb_campaign_objective === 'get_more_website_visitors') {
            fb_campaignobjective = 'Website Visitors';
        }

    }



    const options = {
        chart: {
            id: 'line-chart',
            toolbar: {
                show: false, // Hide the toolbar
            },
        },
        stroke: {
            curve: 'smooth', // Smooth curve for the line
        },
        xaxis: {
            labels: {
                show: false, // Hide the numerical labels
            },
            show: false, // Optionally hide the entire X-axis line
        },
        yaxis: {
            show: false, // Hide the Y-axis
        },
        grid: {
            show: false, // Hide the grid
        },
        colors: ['#FF4560'], // Line color
    };


    const options1 = {
        chart: {
            id: 'line-chart',
            toolbar: {
                show: false, // Hide the toolbar
            },
        },
        stroke: {
            curve: 'smooth', // Smooth curve for the line
        },
        xaxis: {
            labels: {
                show: false, // Hide the numerical labels
            },
            show: false, // Optionally hide the entire X-axis line
        },
        yaxis: {
            show: false, // Hide the Y-axis
        },
        grid: {
            show: false, // Hide the grid
        },
        colors: ['#009688'], // Line color
    };


    const generateValues = (total, numValues) => {
        const baseValues = [200, 300, 500, 700, 800]; // Example base values
        const values = baseValues.slice(0, numValues); // Select first numValues
        const currentTotal = values.reduce((acc, value) => acc + value, 0);

        // Adjust last value to ensure the sum matches totalValue
        const lastValue = total - currentTotal;
        values[values.length - 1] += lastValue;

        return values;
    };

    const values1 = generateValues(100, 5);

    console.log(values1);



    const series = [
        {
            name: 'Data',
            data: [values1], // Sample data points
        },
    ];
    const campaignDetails = {
        location: "New York",
        age: "18-24",
        gender: "Female",
        budget: "$5000",
        pageName: "Sample Page",
        objective: "Increase Engagement",
        websiteLink: "https://example.com",
        campaignName: "Fall Promotion",
        startDate: "2024-10-01",
        endDate: "2024-10-31",
    };






    const whatsapp = {
        chart: {
            type: 'donut',
            offsetY: 20,
        },
        plotOptions: {
            pie: {
                startAngle: -180,
                endAngle: 180,
                customScale: 0.8, // Adjust for semi-donut
                donut: {
                    size: '50%', // Adjust donut size
                },
            },
        },
        colors: ['#FF4560', '#009688', '#FF7F50', '#FFD700'], // Customize colors as needed
        labels: [
            'Total Message',
            'Total Budget',
        ],
        dataLabels: {
            enabled: true,
        },
        legend: {
            position: 'bottom',
        },
    };


    const wseries = [approximate_whatsapp_send_message, whatsapp_budget_amount];

    const email = {
        chart: {
            type: 'donut',
            offsetY: 20,
        },
        plotOptions: {
            pie: {
                startAngle: -180,
                endAngle: 180,
                customScale: 0.8, // Adjust for semi-donut
                donut: {
                    size: '50%', // Adjust donut size
                },
            },
        },
        colors: ['#FF4560', '#009688', '#FF7F50', '#FFD700'], // Customize colors as needed
        labels: [
            'Total Mail',
            'Total Budget',
        ],
        dataLabels: {
            enabled: true,
        },
        legend: {
            position: 'bottom',
        },
    };


    const eseries = [approximate_mail_send_message, mail_budget_amount];


    const sms = {
        chart: {
            type: 'donut',
            offsetY: 20,
        },
        plotOptions: {
            pie: {
                startAngle: -180,
                endAngle: 180,
                customScale: 0.8, // Adjust for semi-donut
                donut: {
                    size: '50%', // Adjust donut size
                },
            },
        },
        colors: ['#FF4560', '#009688', '#FF7F50', '#FFD700'], // Customize colors as needed
        labels: [
            'Total SMS',
            'Total Budget',
        ],
        dataLabels: {
            enabled: true,
        },
        legend: {
            position: 'bottom',
        },
    };

    const smsseries = [parseInt(detaillisting?.approximate_sms_send_message), parseInt(detaillisting?.sms_budget_amount)];


    const ivr = {
        chart: {
            type: 'donut',
            offsetY: 20,
        },
        plotOptions: {
            pie: {
                startAngle: -180,
                endAngle: 180,
                customScale: 0.8, // Adjust for semi-donut
                donut: {
                    size: '50%', // Adjust donut size
                },
            },
        },
        colors: ['#FF4560', '#009688', '#FF7F50', '#FFD700'], // Customize colors as needed
        labels: [
            'Total IVR',
            'Total Budget',
        ],
        dataLabels: {
            enabled: true,
        },
        legend: {
            position: 'bottom',
        },
    };

    const ivrseries = [parseInt(detaillisting?.approximate_ivr_send_message), parseInt(detaillisting?.ivr_budget_amount)];


    const voicebot = {
        chart: {
            type: 'donut',
            offsetY: 20,
        },
        plotOptions: {
            pie: {
                startAngle: -180,
                endAngle: 180,
                customScale: 0.8, // Adjust for semi-donut
                donut: {
                    size: '50%', // Adjust donut size
                },
            },
        },
        colors: ['#FF4560', '#009688', '#FF7F50', '#FFD700'], // Customize colors as needed
        labels: [
            'Total Voice bot',
            'Total Budget',
        ],
        dataLabels: {
            enabled: true,
        },
        legend: {
            position: 'bottom',
        },
    };

    const voicebotseries = [parseInt(detaillisting?.approximate_voicebot_send_message), parseInt(detaillisting?.voicebot_budget_amount)];





    const facebook = {
        chart: {
            type: 'donut',
            offsetY: 20,
        },
        plotOptions: {
            pie: {
                startAngle: -180,
                endAngle: 180,
                customScale: 0.8, // Adjust for semi-donut
                donut: {
                    size: '50%', // Adjust donut size
                },
            },
        },
        colors: ['#FF4560', '#009688'], // Customize colors as needed
        labels: [
            'Estimated Clicks',
            'Estimated Conversions'
        ],
        dataLabels: {
            enabled: true,
        },
        legend: {
            position: 'bottom',
        },
    };






    const removeCommas = (str) => {
        // Ensure str is a string before replacing
        return String(str).replace(/,/g, '');
    };

    const fb_estimated_click_value = removeCommas(fb_estimated_click);

    const removeCommas1 = (str) => {
        // Ensure str is a string before replacing
        return String(str).replace(/,/g, '');
    };

    const fb_estimated_lead_value = removeCommas1(fb_estimated_lead);

    const fseries = [parseInt(fb_estimated_click_value), parseInt(fb_estimated_lead_value)];

    console.log(fseries)
    //const fseries = [1000, 2000];
    console.log(fb_estimated_click);
    console.log(fb_estimated_lead);

    const google = {
        chart: {
            type: 'donut',
            offsetY: 20,
        },
        plotOptions: {
            pie: {
                startAngle: -180,
                endAngle: 180,
                customScale: 0.8, // Adjust for semi-donut
                donut: {
                    size: '50%', // Adjust donut size
                },
            },
        },
        colors: ['#FF4560', '#009688', '#FF7F50', '#FFD700'], // Customize colors as needed
        labels: [
            'Estimated Clicks',
            'Estimated Conversions',
            'Estimated Views',
            'Expected Conversions',
        ],
        dataLabels: {
            enabled: true,
        },
        legend: {
            position: 'bottom',
        },
    };


    const linkedin = {
        chart: {
            type: 'donut',
            offsetY: 20,
        },
        plotOptions: {
            pie: {
                startAngle: -180,
                endAngle: 180,
                customScale: 0.8, // Adjust for semi-donut
                donut: {
                    size: '50%', // Adjust donut size
                },
            },
        },
        colors: ['#FF4560', '#009688', '#FF7F50', '#FFD700'], // Customize colors as needed
        labels: [
            'Estimated Clicks',
            'Estimated leads',
            'Estimated impression',
            'Expected Conversions',
        ],
        dataLabels: {
            enabled: true,
        },
        legend: {
            position: 'bottom',
        },
    };

    const limitCharacters = (text, limit) => {
        return text.length > limit ? text.slice(0, limit) + '...' : text;
    };

    const linkedinseries = [linkedin_estimated_click,
        linkedin_estimated_leads,
        linkedin_estimated_impression,
        linkedin_expected_conversions,];

    /*
    const gseries = [
      /*
      data.youtube_estimated_click,
      data.youtube_estimated_conversion,
      data.youtube_estimated_view,
      data.youtube_expected_conversion,
  
      data: [500, 300, 100, 200, 600],
    ];
  */

    console.log(fb_ad_posting_id);
    const gseries = [youtube_estimated_click, youtube_estimated_view, youtube_estimated_conversion, youtube_expected_conversion];

    // console.log("sankar", detaillisting?.facebook_estimated_clicks);


    // Helper function to format labels

    const handleGender = (gender) => {
        if (gender == 1) {
            return 'Male';
        } else if (gender == 2) {
            return 'Female';
        } else if (gender == 0) {
            return 'All';
        }
    }

    return (
        <>
            <Grid style={styleCss.commonpaddingtop}>

                <Grid container item spacing={2}>

                    {fb_ad_posting_id && fb_ad_posting_id !== 0 ? (
                        <Grid item md={2}>
                            <Card>
                                <CardHeader
                                    avatar={<Avatar sx={{ bgcolor: '#fdfdfd', color: '#fff' }}><FacebookIcon color="primary" /></Avatar>}
                                    title={<Typography variant="h6">{fb_budget_amount}</Typography>}
                                />
                            </Card>
                        </Grid>
                    ) : null}


                    {youtube_ad_posting_id && youtube_ad_posting_id !== 0 ? (
                        <Grid item md={2}>
                            <Card>
                                <CardHeader
                                    avatar={<Avatar sx={{ bgcolor: '#fdfdfd', color: '#fff' }}><GoogleIcon color="primary" /></Avatar>}
                                    title={<Typography variant="h6">{youtube_budget_amount}</Typography>}
                                />
                            </Card>
                        </Grid>
                    ) : null}

                    {linkedin_ad_posting_id && linkedin_ad_posting_id !== 0 ? (
                        <Grid item md={2}>
                            <Card>
                                <CardHeader
                                    avatar={<Avatar sx={{ bgcolor: '#fdfdfd', color: '#fff' }}><LinkedInIcon color="primary" /></Avatar>}
                                    title={<Typography variant="h6">1000</Typography>}
                                />
                            </Card>
                        </Grid>
                    ) : null}


                    {is_whatsapp_checked && is_whatsapp_checked !== 0 ? (
                        <Grid item md={2}>
                            <Card>
                                <CardHeader
                                    avatar={<Avatar sx={{ bgcolor: '#fdfdfd', color: '#fff' }}><WhatsAppIcon color="primary" /></Avatar>}
                                    title={<Typography variant="h6">{whatsapp_budget_amount}</Typography>}
                                />
                            </Card>
                        </Grid>
                    ) : null}

                    {detaillisting?.is_mail_checked && detaillisting?.is_mail_checked !== 0 ? (
                        <Grid item md={2}>
                            <Card>
                                <CardHeader
                                    avatar={<Avatar sx={{ bgcolor: '#fdfdfd', color: '#fff' }}><MailOutlineIcon color="primary" /></Avatar>}
                                    title={<Typography variant="h6">{detaillisting?.mail_budget_amount}</Typography>}
                                />
                            </Card>
                        </Grid>
                    ) : null}


                    {detaillisting?.is_sms_checked && detaillisting?.is_sms_checked !== 0 ? (
                        <Grid item md={2}>
                            <Card>
                                <CardHeader
                                    avatar={<Avatar sx={{ bgcolor: '#fdfdfd', color: '#fff' }}><SmsIcon color="primary" /></Avatar>}
                                    title={<Typography variant="h6">{detaillisting?.sms_budget_amount}</Typography>}
                                />
                            </Card>
                        </Grid>
                    ) : null}


                    {detaillisting?.is_ivr_checked && detaillisting?.is_ivr_checked !== 0 ? (
                        <Grid item md={2}>
                            <Card>
                                <CardHeader
                                    avatar={<Avatar sx={{ bgcolor: '#fdfdfd', color: '#fff' }}><DialpadIcon color="primary" /></Avatar>}
                                    title={<Typography variant="h6">{detaillisting?.ivr_budget_amount}</Typography>}
                                />
                            </Card>
                        </Grid>
                    ) : null}

                    {detaillisting?.is_voicebot_checked && detaillisting?.is_voicebot_checked !== 0 ? (
                        <Grid item md={2}>
                            <Card>
                                <CardHeader
                                    avatar={<Avatar sx={{ bgcolor: '#fdfdfd', color: '#fff' }}><VoiceChatIcon color="primary" /></Avatar>}
                                    title={<Typography variant="h6">{detaillisting?.voicebot_budget_amount}</Typography>}
                                />
                            </Card>
                        </Grid>
                    ) : null}
                </Grid>

                {/* START FACEBOOK */}
                {fb_ad_posting_id && fb_ad_posting_id !== 0 ? (
                    <Grid container spacing={2} >
                        <Grid item xs={12} sm={12}>
                            <Typography
                                variant="h6"
                                gutterBottom
                                sx={{ mt: 2, textAlign: 'center', fontSize: '16px', color: '#4267B2', textDecoration: 'underline' }}
                            >
                                Facebook Campaign Overview
                            </Typography>
                        </Grid>

                        <Grid item xs={4} sm={4}>
                            <Box
                                border={1}
                                borderColor="grey.300"
                                borderRadius={1}
                                p={2}
                                bgcolor="#eee7e7"
                                height="200px"
                            >
                                <Typography variant="subtitle1" sx={{ fontSize: '0.875rem' }}>
                                    <strong style={{ fontFamily: 'inter', fontSize: '13px', }}>Campaign:</strong> {fb_campaign}
                                </Typography>
                                <Typography variant="subtitle1" sx={{ fontSize: '0.875rem' }}>
                                    <strong style={{ fontFamily: 'inter', fontSize: '13px', }}>Page Name:</strong> {fb_screen_name}
                                </Typography>
                                <Typography variant="subtitle1" sx={{ fontSize: '0.875rem' }}>
                                    <strong style={{ fontFamily: 'inter', fontSize: '13px', }}>Objective:</strong> {detaillisting.fb_campaign_objective}
                                </Typography>
                                <Typography variant="subtitle1" sx={{ fontSize: '0.875rem' }}>
                                    <strong style={{ fontFamily: 'inter', fontSize: '13px', }}>Website Link:</strong>
                                    <a href={fb_website_link} target="_blank" rel="noopener noreferrer">{fb_website_link}</a>
                                </Typography>
                                <Typography variant="subtitle1" sx={{ fontSize: '0.875rem' }}>
                                    <strong style={{ fontFamily: 'inter', fontSize: '13px', }}>Gender:</strong> {handleGender(fb_gender)}
                                </Typography>
                                <Typography variant="subtitle1" sx={{ fontSize: '0.875rem' }}>
                                    <strong style={{ fontFamily: 'inter', fontSize: '13px', }}>Location:</strong> {fbcity !== '' ? fbcity : ''} {fbstate !== '' ? fbstate : ''} {fbcountryName !== '' ? fbcountryName : ''}
                                </Typography>
                                <Typography variant="subtitle1" sx={{ fontSize: '0.875rem' }}>
                                    <strong style={{ fontFamily: 'inter', fontSize: '13px', }}>Start Date:</strong> {start_date}
                                </Typography>
                                <Typography variant="subtitle1" sx={{ fontSize: '0.875rem' }}>
                                    <strong style={{ fontFamily: 'inter', fontSize: '13px', }}>End Date:</strong> {end_date}
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={5}>
                            <Card sx={{ background: '#eaeadc', height: "230px" }}>
                                <CardContent>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Grid>
                                                <Box width="100%" maxWidth="300px" mx="auto">
                                                    <Chart options={facebook} series={fseries} type="donut" height={190} />
                                                </Box>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid container item xs={3}>
                            <Grid>
                                <Card sx={{ background: '#e8f2f4', height: "230px" }}>
                                    <CardContent>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Avatar alt="Profile Picture" src={detaillisting.page_profile_image} />
                                            <Typography variant="body1" style={{ marginLeft: '10px', fontSize: '12px' }}>
                                                <span style={{ fontSize: 'small' }}> Sponsored</span><br />
                                                {detaillisting?.page_name}
                                            </Typography>
                                        </div>

                                        <Typography variant="body1">
                                            <img
                                                src={detaillisting?.fb_ads_image_url}
                                                alt="Description of Image"
                                                style={{ width: '100%', height: '100px', marginTop: '10px' }} // Adjust styles as needed
                                            />
                                        </Typography>
                                        <Typography variant="caption" style={{ marginLeft: 'left' }}>
                                            {detaillisting?.fb_ads_headlines}
                                        </Typography>
                                        <br />
                                        <Typography variant="caption" style={{ marginLeft: 'left' }}>
                                            {limitCharacters(detaillisting?.fb_ads_content, 60)}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : null}

                {youtube_ad_posting_id && youtube_ad_posting_id !== 0 ? (
                    <Grid container spacing={2} >

                        <Grid item xs={12} sm={12}>
                            <Typography
                                variant="h6"
                                gutterBottom
                                sx={{ mt: 2, textAlign: 'center', fontSize: '1.10rem', color: '#4267B2', textDecoration: 'underline' }}
                            >
                                Google Campaign Overview
                            </Typography>
                        </Grid>

                        <Grid item xs={4} sm={4}>
                            <Box
                                border={1}
                                borderColor="grey.300"
                                borderRadius={1}
                                p={2}
                                bgcolor="#eee7e7"
                                height="200px"
                            >
                                <Typography variant="subtitle1" sx={{ fontSize: '0.875rem' }}>
                                    <strong style={{ fontFamily: 'inter', fontSize: '13px', }}>Campaign:</strong> {youtube_campaign}
                                </Typography>
                                <Typography variant="subtitle1" sx={{ fontSize: '0.875rem' }}>
                                    <strong style={{ fontFamily: 'inter', fontSize: '13px', }}>Account Name:</strong> {youtube_screen_name}
                                </Typography>
                                <Typography variant="subtitle1" sx={{ fontSize: '0.875rem' }}>
                                    <strong style={{ fontFamily: 'inter', fontSize: '13px', }}>Objective:</strong> {ytubecampaignobjective}
                                </Typography>
                                <Typography variant="subtitle1" sx={{ fontSize: '0.875rem' }}>
                                    <strong style={{ fontFamily: 'inter', fontSize: '13px', }}>Website Link:</strong>
                                    <a href={campaignDetails.websiteLink} target="_blank" rel="noopener noreferrer">{campaignDetails.websiteLink}</a>
                                </Typography>
                                <Typography variant="subtitle1" sx={{ fontSize: '0.875rem' }}>
                                    <strong style={{ fontFamily: 'inter', fontSize: '13px' }}>Location:</strong> {ycity !== '' ? ycity : ''} {ystate !== '' ? ystate : ''} {ycountryName !== '' ? ycountryName : ''}
                                </Typography>
                                <Typography variant="subtitle1" sx={{ fontSize: '0.875rem' }}>
                                    <strong style={{ fontFamily: 'inter', fontSize: '13px' }}>Budget:</strong> {youtube_budget_amount}
                                </Typography>

                                <Typography variant="subtitle1" sx={{ fontSize: '0.875rem' }}>
                                    <strong style={{ fontFamily: 'inter', fontSize: '13px', }}>Start Date:</strong> {start_date}
                                </Typography>
                                <Typography variant="subtitle1" sx={{ fontSize: '0.875rem' }}>
                                    <strong style={{ fontFamily: 'inter', fontSize: '13px', }}>End Date:</strong> {end_date}
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={5}>
                            <Card sx={{ background: '#eaeadc', height: "230px" }}>
                                <CardContent>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Grid>
                                                <Box width="100%" maxWidth="300px" mx="auto">
                                                    <Chart options={google} series={gseries} type="donut" height={190} />

                                                </Box>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid container item xs={3}>
                            <Grid>
                                <Card sx={{
                                }}>
                                    <CardContent>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Avatar alt="Profile Picture" src={detaillisting?.youtube_profile} />
                                            <Typography variant="body1" style={{ marginLeft: '10px', fontSize: '12px' }}>
                                                <span style={{ fontSize: 'small' }}> Sponsored</span><br />
                                                {detaillisting?.youtube_screen_name}
                                            </Typography>
                                        </div>

                                        <Typography variant="body1">
                                            <img
                                                src={detaillisting?.ytube_ads_image_url}
                                                alt="Description of Image"
                                                style={{ width: '100%', height: '100px', marginTop: '10px' }} // Adjust styles as needed
                                            />
                                        </Typography>
                                        <Typography variant="caption" style={{ marginLeft: 'left' }}>
                                            {ytube_ads_headlines}
                                        </Typography>
                                        <br />
                                        <Typography variant="caption" style={{ marginLeft: 'left' }}>
                                            {limitCharacters(ytube_ads_content, 60)}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : null}
                {/* END GOOGLE */}

                {linkedin_ad_posting_id && linkedin_ad_posting_id !== 0 ? (
                    <Grid container spacing={2} >

                        <Grid item xs={12} sm={12}>
                            <Typography
                                variant="h6"
                                gutterBottom
                                sx={{ mt: 2, textAlign: 'center', fontSize: '1.10rem', color: '#4267B2', textDecoration: 'underline' }}
                            >
                                Linkedin Campaign Overview
                            </Typography>
                        </Grid>

                        <Grid item xs={4} sm={4}>
                            <Box
                                border={1}
                                borderColor="grey.300"
                                borderRadius={1}
                                p={2}
                                bgcolor="#eee7e7"
                                height="200px"
                            >
                                <Typography variant="subtitle1" sx={{ fontSize: '0.875rem' }}>
                                    <strong style={{ fontFamily: 'inter', fontSize: '13px', }}>Campaign:</strong> {detaillisting?.linkedin_campaign_name}
                                </Typography>
                                <Typography variant="subtitle1" sx={{ fontSize: '0.875rem' }}>
                                    <strong style={{ fontFamily: 'inter', fontSize: '13px', }}>Account Name:</strong> {detaillisting.linkedin_name}
                                </Typography>
                                <Typography variant="subtitle1" sx={{ fontSize: '0.875rem' }}>
                                    <strong style={{ fontFamily: 'inter', fontSize: '13px', }}>Objective:</strong> {linkedincampaignobjective}
                                </Typography>
                                <Typography variant="subtitle1" sx={{ fontSize: '0.875rem' }}>
                                    <strong style={{ fontFamily: 'inter', fontSize: '13px', }}>Website Link:</strong>
                                    <a href={detaillisting.linkedin_ads_website_url} target="_blank" rel="noopener noreferrer">{detaillisting.linkedin_ads_website_url}</a>
                                </Typography>
                                <Typography variant="subtitle1" sx={{ fontSize: '0.875rem' }}>
                                    <strong style={{ fontFamily: 'inter', fontSize: '13px' }}>Location:</strong> {linkedincity !== '' ? linkedincity : ''} {linkedinstate !== '' ? linkedinstate : ''} {linkedincountryName !== '' ? linkedincountryName : ''}
                                </Typography>
                                <Typography variant="subtitle1" sx={{ fontSize: '0.875rem' }}>
                                    <strong style={{ fontFamily: 'inter', fontSize: '13px' }}>Budget:</strong> {detaillisting?.linkedin_budget_amount}
                                </Typography>

                                <Typography variant="subtitle1" sx={{ fontSize: '0.875rem' }}>
                                    <strong style={{ fontFamily: 'inter', fontSize: '13px', }}>Start Date:</strong> {start_date}
                                </Typography>
                                <Typography variant="subtitle1" sx={{ fontSize: '0.875rem' }}>
                                    <strong style={{ fontFamily: 'inter', fontSize: '13px', }}>End Date:</strong> {end_date}
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={5}>
                            <Card sx={{ background: '#eaeadc', height: "230px" }}>
                                <CardContent>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Grid>
                                                <Box width="100%" maxWidth="300px" mx="auto">
                                                    <Chart options={linkedin} series={linkedinseries} type="donut" height={190} />

                                                </Box>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid container item xs={3}>
                            <Grid>
                                <Card sx={{
                                }}>
                                    <CardContent>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Avatar alt="Profile Picture" src={detaillisting?.linkedin_profile} />
                                            <Typography variant="body1" style={{ marginLeft: '10px', fontSize: '12px' }}>
                                                <span style={{ fontSize: 'small' }}> Sponsored</span><br />
                                                {detaillisting?.linkedin_name}
                                            </Typography>
                                        </div>

                                        <Typography variant="body1">
                                            <img
                                                src={detaillisting?.linkedin_ads_image_url}
                                                alt="Description of Image"
                                                style={{ width: '100%', height: '100px', marginTop: '10px' }} // Adjust styles as needed
                                            />
                                        </Typography>
                                        <Typography variant="caption" style={{ marginLeft: 'left' }}>
                                            {detaillisting?.linkedin_ads_headlines}
                                        </Typography>
                                        <br />
                                        <Typography variant="caption" style={{ marginLeft: 'left' }}>
                                            {limitCharacters(detaillisting?.linkedin_ads_content, 60)}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : null}



                {/* START WHATSAPP */}
                <Grid container spacing={2}>
                    {is_whatsapp_checked ? (
                        <Grid item md={6}>
                            <Typography
                                variant="h6"
                                gutterBottom
                                sx={{
                                    mt: 2,
                                    textAlign: 'center',
                                    fontSize: '1.10rem',
                                    color: '#4267B2',
                                    textDecoration: 'underline',
                                    marginBottom: '0px'
                                }}
                            >
                                Whatsapp Campaign Overview
                            </Typography>
                        </Grid>
                    ) : null}


                    <Grid item md={6}>
                        {is_mail_checked && is_mail_checked !== 0 ? (
                            <Typography
                                variant="h6"
                                gutterBottom
                                sx={{ mt: 2, textAlign: 'center', fontSize: '1.10rem', color: '#4267B2', textDecoration: 'underline' }}
                            >
                                Mail Campaign Overview
                            </Typography>
                        ) : null}
                    </Grid>

                    {/* WHATSAPP */}


                    {is_whatsapp_checked && is_whatsapp_checked !== 0 ?  (
                        <><Grid item md={3} sx={{ mt: 1 }}>
                            <Box
                                border={1}
                                borderColor="grey.300"
                                borderRadius={1}
                                p={2}
                                bgcolor="#eee7e7"
                                height="190px"
                            >

                                <Typography variant="subtitle1" sx={{ fontSize: '0.875rem' }}>
                                    <strong style={{ fontFamily: 'inter', fontSize: '13px' }}>Campaign:</strong> {detaillisting?.whatsapp_campaign_name}
                                </Typography>
                                <Typography variant="subtitle1" sx={{ fontSize: '0.875rem' }}>
                                    <strong style={{ fontFamily: 'inter', fontSize: '13px' }}>Objective:</strong> WhatsApp
                                </Typography>
                                <Typography variant="subtitle1" sx={{ fontSize: '0.875rem' }}>
                                    <strong style={{ fontFamily: 'inter', fontSize: '13px' }}>Cost Per Message:</strong> {detaillisting?.cost_for_whatsapp}
                                </Typography>
                                <Typography variant="subtitle1" sx={{ fontSize: '0.875rem' }}>
                                    <strong style={{ fontFamily: 'inter', fontSize: '13px' }}>Total Message: </strong> {parseInt(detaillisting?.approximate_whatsapp_send_message)}

                                </Typography>
                                <Typography variant="subtitle1" sx={{ fontSize: '0.875rem' }}>
                                    <strong style={{ fontFamily: 'inter', fontSize: '13px' }}>Budget:</strong> {detaillisting?.whatsapp_budget_amount}
                                </Typography>
                                <Typography variant="subtitle1" sx={{ fontSize: '0.875rem' }}>
                                    <strong style={{ fontFamily: 'inter', fontSize: '13px' }}>Start Date:</strong> {start_date}
                                </Typography>
                                <Typography variant="subtitle1" sx={{ fontSize: '0.875rem' }}>
                                    <strong style={{ fontFamily: 'inter', fontSize: '13px' }}>End Date:</strong> {end_date}
                                </Typography>
                            </Box>
                        </Grid>
                            <Grid item md={3} sx={{ mt: 1, height: '180px' }} >
                                <Card sx={{ background: '#eaeadc' }}>
                                    <CardContent>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Box width="100%" maxWidth="600px" mx="auto">
                                                    <Chart options={whatsapp} series={wseries} type="donut" height={180} />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid></>
                    ) : null}
                    {/* END WHATSAPP */}



                    {/* MAIL */}
                    {is_mail_checked && is_mail_checked !== 0 ? (
                        <><Grid item md={3} sx={{ mt: 1 }}>
                            <Box
                                border={1}
                                borderColor="grey.300"
                                borderRadius={1}
                                p={2}
                                bgcolor="#eee7e7"
                                height="190px"
                            >

                                <Typography variant="subtitle1" sx={{ fontSize: '0.875rem' }}>
                                    <strong style={{ fontFamily: 'inter', fontSize: '13px' }}>Campaign:</strong> {detaillisting?.mail_campaign_name}
                                </Typography>
                                <Typography variant="subtitle1" sx={{ fontSize: '0.875rem' }}>
                                    <strong style={{ fontFamily: 'inter', fontSize: '13px' }}>Objective:</strong> Mail
                                </Typography>
                                <Typography variant="subtitle1" sx={{ fontSize: '0.875rem' }}>
                                    <strong style={{ fontFamily: 'inter', fontSize: '13px' }}>Cost Per Message:</strong> {detaillisting?.cost_for_mail}
                                </Typography>
                                <Typography variant="subtitle1" sx={{ fontSize: '0.875rem' }}>
                                    <strong style={{ fontFamily: 'inter', fontSize: '13px' }}>Total Message:</strong> {detaillisting?.approximate_mail_send_message}

                                </Typography>
                                <Typography variant="subtitle1" sx={{ fontSize: '0.875rem' }}>
                                    <strong style={{ fontFamily: 'inter', fontSize: '13px' }}>Budget:</strong> {detaillisting?.mail_budget_amount}
                                </Typography>
                                <Typography variant="subtitle1" sx={{ fontSize: '0.875rem' }}>
                                    <strong style={{ fontFamily: 'inter', fontSize: '13px' }}>Start Date:</strong> {start_date}
                                </Typography>
                                <Typography variant="subtitle1" sx={{ fontSize: '0.875rem' }}>
                                    <strong style={{ fontFamily: 'inter', fontSize: '13px' }}>End Date:</strong> {end_date}
                                </Typography>
                            </Box>
                        </Grid><Grid item md={3} sx={{ mt: 1 }}>
                                <Card sx={{ background: '#eaeadc' }}>
                                    <CardContent>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Box width="100%" maxWidth="600px" mx="auto">
                                                    <Chart options={email} series={eseries} type="donut" height={180} />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid></>
                    ) : null}
                    {/* END MAIL */}
                </Grid>


                <Grid container spacing={2}>

                    <Grid item md={6}>
                        {detaillisting?.is_sms_checked && detaillisting?.is_sms_checked != 0 ? (
                            <Typography
                                variant="h6"
                                gutterBottom
                                sx={{ mt: 2, textAlign: 'center', fontSize: '1.10rem', color: '#4267B2', textDecoration: 'underline', marginBottom: '0px' }}
                            >
                                SMS Campaign Overview
                            </Typography>
                        ) : null}
                    </Grid>

                    <Grid item md={6}>
                        {detaillisting?.is_ivr_checked && detaillisting?.is_ivr_checked != 0 ? (
                            <Typography
                                variant="h6"
                                gutterBottom
                                sx={{ mt: 2, textAlign: 'center', fontSize: '1.10rem', color: '#4267B2', textDecoration: 'underline' }}
                            >
                                IVR Campaign Overview
                            </Typography>
                        ): null}
                    </Grid>
                    {/* WHATSAPP */}


                    {detaillisting?.is_sms_checked && detaillisting?.is_sms_checked !== 0 ? (
                        <><Grid item md={3} sx={{ mt: 1 }}>
                            <Box
                                border={1}
                                borderColor="grey.300"
                                borderRadius={1}
                                p={2}
                                bgcolor="#eee7e7"
                                height="190px"
                            >

                                <Typography variant="subtitle1" sx={{ fontSize: '0.875rem' }}>
                                    <strong style={{ fontFamily: 'inter', fontSize: '13px' }}>Campaign:</strong> {detaillisting?.sms_campaign_name}
                                </Typography>
                                <Typography variant="subtitle1" sx={{ fontSize: '0.875rem' }}>
                                    <strong style={{ fontFamily: 'inter', fontSize: '13px' }}>Objective:</strong> Sms
                                </Typography>
                                <Typography variant="subtitle1" sx={{ fontSize: '0.875rem' }}>
                                    <strong style={{ fontFamily: 'inter', fontSize: '13px' }}>Cost Per Message:</strong> {detaillisting?.cost_for_sms}
                                </Typography>
                                <Typography variant="subtitle1" sx={{ fontSize: '0.875rem' }}>
                                    <strong style={{ fontFamily: 'inter', fontSize: '13px' }}>Total Message: </strong> {parseInt(detaillisting?.approximate_sms_send_message)}

                                </Typography>
                                <Typography variant="subtitle1" sx={{ fontSize: '0.875rem' }}>
                                    <strong style={{ fontFamily: 'inter', fontSize: '13px' }}>Budget:</strong> {detaillisting?.sms_budget_amount}
                                </Typography>
                                <Typography variant="subtitle1" sx={{ fontSize: '0.875rem' }}>
                                    <strong style={{ fontFamily: 'inter', fontSize: '13px' }}>Start Date:</strong> {start_date}
                                </Typography>
                                <Typography variant="subtitle1" sx={{ fontSize: '0.875rem' }}>
                                    <strong style={{ fontFamily: 'inter', fontSize: '13px' }}>End Date:</strong> {end_date}
                                </Typography>
                            </Box>
                        </Grid><Grid item md={3} sx={{ mt: 1 }}>
                                <Card sx={{ background: '#eaeadc' }}>
                                    <CardContent>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Box width="100%" maxWidth="600px" mx="auto">
                                                    <Chart options={ivr} series={ivrseries} type="donut" height={180} />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid></>
                    ) : null}
                    {/* END WHATSAPP */}



                    {/* MAIL */}
                    {detaillisting?.is_ivr_checked && detaillisting?.is_ivr_checked !== 0 ? (
                        <><Grid item md={3} sx={{ mt: 1 }}>
                            <Box
                                border={1}
                                borderColor="grey.300"
                                borderRadius={1}
                                p={2}
                                bgcolor="#eee7e7"
                                height="190px"
                            >

                                <Typography variant="subtitle1" sx={{ fontSize: '0.875rem' }}>
                                    <strong style={{ fontFamily: 'inter', fontSize: '13px' }}>Campaign:</strong> {detaillisting?.ivr_campaign_name}
                                </Typography>
                                <Typography variant="subtitle1" sx={{ fontSize: '0.875rem' }}>
                                    <strong style={{ fontFamily: 'inter', fontSize: '13px' }}>Objective:</strong> IVR
                                </Typography>
                                <Typography variant="subtitle1" sx={{ fontSize: '0.875rem' }}>
                                    <strong style={{ fontFamily: 'inter', fontSize: '13px' }}>Cost Per Message:</strong> {detaillisting?.cost_for_ivr}
                                </Typography>
                                <Typography variant="subtitle1" sx={{ fontSize: '0.875rem' }}>
                                    <strong style={{ fontFamily: 'inter', fontSize: '13px' }}>Total Message:</strong> {detaillisting?.approximate_ivr_send_message}

                                </Typography>
                                <Typography variant="subtitle1" sx={{ fontSize: '0.875rem' }}>
                                    <strong style={{ fontFamily: 'inter', fontSize: '13px' }}>Budget:</strong> {detaillisting?.ivr_budget_amount}
                                </Typography>
                                <Typography variant="subtitle1" sx={{ fontSize: '0.875rem' }}>
                                    <strong style={{ fontFamily: 'inter', fontSize: '13px' }}>Start Date:</strong> {start_date}
                                </Typography>
                                <Typography variant="subtitle1" sx={{ fontSize: '0.875rem' }}>
                                    <strong style={{ fontFamily: 'inter', fontSize: '13px' }}>End Date:</strong> {end_date}
                                </Typography>
                            </Box>
                        </Grid><Grid item md={3} sx={{ mt: 1 }}>
                                <Card sx={{ background: '#eaeadc' }}>
                                    <CardContent>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Box width="100%" maxWidth="600px" mx="auto">
                                                    <Chart options={ivr} series={ivrseries} type="donut" height={180} />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid></>
                    ) : null}
                    {/* END MAIL */}
                </Grid>


                <Grid container spacing={2}>
                    <Grid item md={6}>
                        {detaillisting?.is_voicebot_checked && detaillisting?.is_voicebot_checked !== 0 ? (
                            <Typography
                                variant="h6"
                                gutterBottom
                                sx={{ mt: 2, textAlign: 'center', fontSize: '1.10rem', color: '#4267B2', textDecoration: 'underline', mb: 0 }}
                            >
                                Voice Bot Campaign Overview
                            </Typography>
                        ): null}
                    </Grid>
                    <Grid item md={6}>
                        <Typography
                            variant="h6"
                            gutterBottom
                            sx={{ mt: 2, textAlign: 'center', fontSize: '1.10rem', color: '#4267B2', textDecoration: 'underline' }}
                        >
                            {/* You can add content here if needed, or remove this if no content is required */}
                        </Typography>
                    </Grid>

                    {/* IVR Check */}
                    {detaillisting?.is_voicebot_checked && detaillisting?.is_voicebot_checked !== 0 ? (
                        <>
                            <Grid item md={3} sx={{ mt: 1 }}>
                                <Box
                                    border={1}
                                    borderColor="grey.300"
                                    borderRadius={1}
                                    p={2}
                                    bgcolor="#eee7e7"
                                    height="190px"
                                >
                                    <Typography variant="subtitle1" sx={{ fontSize: '0.875rem' }}>
                                        <strong style={{ fontFamily: 'Inter', fontSize: '13px' }}>Campaign:</strong> {detaillisting?.voicebot_campaign_name}
                                    </Typography>
                                    <Typography variant="subtitle1" sx={{ fontSize: '0.875rem' }}>
                                        <strong style={{ fontFamily: 'Inter', fontSize: '13px' }}>Objective:</strong> Voice bot
                                    </Typography>
                                    <Typography variant="subtitle1" sx={{ fontSize: '0.875rem' }}>
                                        <strong style={{ fontFamily: 'Inter', fontSize: '13px' }}>Cost Per Message:</strong> {detaillisting?.cost_for_voicebot}
                                    </Typography>
                                    <Typography variant="subtitle1" sx={{ fontSize: '0.875rem' }}>
                                        <strong style={{ fontFamily: 'Inter', fontSize: '13px' }}>Total Message:</strong> {detaillisting?.approximate_voicebot_send_message}
                                    </Typography>
                                    <Typography variant="subtitle1" sx={{ fontSize: '0.875rem' }}>
                                        <strong style={{ fontFamily: 'Inter', fontSize: '13px' }}>Budget:</strong> {detaillisting?.voicebot_budget_amount}
                                    </Typography>
                                    <Typography variant="subtitle1" sx={{ fontSize: '0.875rem' }}>
                                        <strong style={{ fontFamily: 'Inter', fontSize: '13px' }}>Start Date:</strong> {start_date}
                                    </Typography>
                                    <Typography variant="subtitle1" sx={{ fontSize: '0.875rem' }}>
                                        <strong style={{ fontFamily: 'Inter', fontSize: '13px' }}>End Date:</strong> {end_date}
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item md={3} sx={{ mt: 1 }}>
                                <Card sx={{ background: '#eaeadc' }}>
                                    <CardContent>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Box width="100%" maxWidth="600px" mx="auto">
                                                    <Chart options={voicebot} series={voicebotseries} type="donut" height={180} />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </>
                    ) : null}

                </Grid>


                <Grid item xs={6} style={{ textAlign: 'right' }}>
                    <Link to="/budgetlisting" style={{ textDecoration: 'none' }}>
                        <Button variant="contained">
                            List Budget
                        </Button>
                    </Link>
                </Grid>



            </Grid>

        </>
    )
}

export default BudgetlistView