import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { nonAuthpostAxios } from "../Utilities/commonAxios";

const initialState = {
  postItems: [],
  isSessionExpired: false,
  isLogin: false,
  isLogout: false,
};

export const authLogin = createAsyncThunk(
  "auth/authLogin",
  async (inputData, thunkAPI) => {
    try {
      const { data: loginResponse } = await nonAuthpostAxios({
        url : `${process.env.REACT_APP_BACKENDURL}api/login`,
        // url: `${process.env.REACT_APP_BACKENDURL}api/login`,
        data: inputData,
      });
      return loginResponse;
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message });
    }
  }
);

export const socialAuthLogin = createAsyncThunk(
  "auth/socialAuthLogin",
  async (inputData, thunkAPI) => {
    try {
      const { data: socialLoginResponse } = await nonAuthpostAxios({
        url: `${process.env.REACT_APP_BACKENDURL}api/sso-login`,
        data: { api_user_token: inputData.apiUserToken },
      });
      return socialLoginResponse;
    } catch (err) {
      return thunkAPI.rejectWithValue({ error: err.message });
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateUserProfile: (state, action) => {
      state.postItems.data.userDetails.image_logo = action.payload
    },
    updateCompanyProfile: (state, action) => {
      state.postItems.data.userDetails.companylogo = action.payload
    },
    manageUserSession: (state, { payload }) => {
      if (payload === "expired" || payload === "logout") state.postItems = [];
      state.isSessionExpired = payload === "expired" ? true : false;
      state.isLogin = false;
      state.isLogout = payload === "logout" ? true : false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authLogin.pending, (state, action) => {
        state.postItems = [];
        state.isSessionExpired = false;
        state.isLogin = false;
        state.isLogout = false;
      })
      .addCase(authLogin.fulfilled, (state, action) => {
        state.postItems = action.payload;
        state.isSessionExpired = false;
        state.isLogin = true;
        state.isLogout = false;
      })
      .addCase(authLogin.rejected, (state, action) => {
        state.postItems = [];
        state.isSessionExpired = false;
        state.isLogin = false;
        state.isLogout = false;
      })
      .addCase(socialAuthLogin.fulfilled, (state, action) => {
        state.postItems = action.payload;
        state.isSessionExpired = false;
        state.isLogin = true;
        state.isLogout = false;
      });
  },
});

export const { manageUserSession ,updateUserProfile,updateCompanyProfile } = authSlice.actions;
export default authSlice.reducer;
